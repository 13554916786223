import React from "react";

interface ModalProps {
  show: boolean;
  fileName: string;
  handleClose: () => void;
  sameFile: () => void;
}

const DuplicateModal: React.FC<ModalProps> = ({
  show,
  handleClose,
  fileName,
  sameFile,
}) => {
  if (!show) {
    return null;
  }

  const modalBackdropStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    minWidth: "250px",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const modalContentStyle: React.CSSProperties = {
    backgroundColor: "#fff",
    padding: "15px",
    borderRadius: "10px",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "900px",
    width: "90%",
  };

  const headerStyle: React.CSSProperties = {
    marginBottom: "20px",
    fontSize: "20px",
    fontWeight: "bold",
  };

  const buttonContainerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    marginTop: "50px",
    marginBottom: "50px",
  };

  const buttonStyle: React.CSSProperties = {
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
  };

  const uploadAnotherButtonStyle: React.CSSProperties = {
    ...buttonStyle,
    backgroundColor: "#007bff",
    color: "#fff",
  };
  return (
    <div style={modalBackdropStyle} onClick={handleClose}>
      <div style={modalContentStyle} onClick={(e) => e.stopPropagation()}>
        <h2 style={headerStyle}>File Processing Notification</h2>
        <p>
          We have identified that a file with the name {fileName} already exists
          in our system.
          <br /> As a result, we will proceed with processing this existing file
          to ensure continuity and avoid duplication.
        </p>
        <div style={buttonContainerStyle}>
          <button style={uploadAnotherButtonStyle} onClick={sameFile}>
            Use Existing
          </button>
          <button
            style={{
              ...uploadAnotherButtonStyle,
              marginLeft: "20px",
            }}
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default DuplicateModal;
