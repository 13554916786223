import React, { CSSProperties, ChangeEvent } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Question } from "../../interfaces/aiDashboard.interface";
import TextField from "@mui/material/TextField";
import GridDeleteIcon from "@mui/icons-material/Delete";

interface QuestionAskedDialogProps {
  open: boolean;
  onClose: () => void;
  questionAsked: Question[];
  onQuestionAskedChnage: (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => void;
  onAddQuestion: () => void;
  onRemoveQuestion: (index: number) => void;
}

const QuestionAskedBox: React.FC<QuestionAskedDialogProps> = ({
  open,
  onClose,
  questionAsked,
  onQuestionAskedChnage,
  onAddQuestion,
  onRemoveQuestion,
}) => {
  const styles: { [key: string]: CSSProperties } = {
    label: {
      fontWeight: "bold",
      padding: "10px",
      width: "20px", // Align the text to the right within the label cell
    },
    dataCell: {
      width: "100%", // Make the cell take up the full width of the table
      verticalAlign: "middle", // Vertically center the content
      textAlign: "left", // Left align the content (for the inputs and icons)
    },
    label2: {
      fontWeight: "bold",
      padding: "5px 20px",
    },
    input: {
      marginTop: "3px",
      verticalAlign: "top",
      padding: "10px",
      border: "1px solid #ccc",
      width: "200px",
      borderRadius: "4px", // Make input take full width of its cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    button2: {
      marginLeft: "20px",
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Manage Question Asked</DialogTitle>
      <DialogContent>
        {/* Fund news Member Form */}
        {questionAsked.map((news, index) => (
          <React.Fragment key={index}>
            <tr>
              <td style={styles.label}>News URL:</td>
              <td style={styles.dataCell}>
                <TextField
                  rows={news.title.length / 50}
                  style={{
                    marginTop: "5px",
                    width: "95%",
                    background: "#fff",
                    borderColor: "#007bff",
                    borderWidth: "1px",
                    borderRadius: "4px", // Rounded corners
                    marginBottom: "5px",
                  }}
                  multiline
                  variant="outlined"
                  label="News URL"
                  type="text"
                  name="url"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onQuestionAskedChnage(index, e)
                  }
                  value={news.url}
                />
                <GridDeleteIcon
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    marginTop: "15px",
                  }}
                  onClick={() => onRemoveQuestion(index)}
                />
              </td>
            </tr>
            <tr>
              <td style={styles.label}>News Title:</td>
              <td style={styles.dataCell}>
                <TextField
                  rows={news.title.length / 50}
                  style={{
                    marginTop: "5px",
                    width: "100%",
                    background: "#fff",
                    borderColor: "#007bff",
                    borderWidth: "1px",
                    borderRadius: "4px",
                    marginBottom: "5px",
                  }}
                  multiline
                  variant="outlined"
                  label="News Title"
                  type="text"
                  name="title"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onQuestionAskedChnage(index, e)
                  }
                  value={news.title}
                />
              </td>
            </tr>
          </React.Fragment>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onAddQuestion} color="primary">
          Add New News
        </Button>
        <Button onClick={onClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionAskedBox;
