import React from "react";

interface ModalProps {
  show: boolean;
  handleUploadAnother: () => void;
  handleMoveToWorkflow: () => void;
  email: string;
  fundName: string;
  portfolioName?: string;
  fileName: string;
  handleClose: () => void;
  handleNextPage: () => void;
  isPortfolio: boolean;
  isTool?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  show,
  handleClose,
  handleUploadAnother,
  handleMoveToWorkflow,
  email,
  fundName,
  fileName,
  handleNextPage,
  isPortfolio,
  portfolioName,
    isTool
}) => {
  if (!show) {
    return null;
  }

  const modalBackdropStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    minWidth: "300px",

    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const modalContentStyle: React.CSSProperties = {
    backgroundColor: "#fff",
    padding: "30px",
    borderRadius: "10px",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "900px",
    width: "90%",
  };

  const headerStyle: React.CSSProperties = {
    marginBottom: "20px",
    fontSize: "20px",
    fontWeight: "bold",
  };

  const buttonContainerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    marginTop: "50px",
    marginBottom: "50px",
  };

  const buttonStyle: React.CSSProperties = {
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
  };

  const uploadAnotherButtonStyle: React.CSSProperties = {
    ...buttonStyle,
    backgroundColor: "#007bff",
    color: "#fff",
    marginRight: "40px",
  };

  const moveToWorkflowButtonStyle: React.CSSProperties = {
    ...buttonStyle,
    backgroundColor: "#007bff",
    color: "#fff",
    marginRight: "40px",
  };

  return (
    <div style={modalBackdropStyle} onClick={handleClose}>
      <div style={modalContentStyle} onClick={(e) => e.stopPropagation()}>
        <h2 style={headerStyle}>File Upload Successful</h2>
        <p>
          {isPortfolio ? (
            <>
              We are currently extracting information from the file{" "}
              <strong>{fileName}</strong> for fund: <strong>{fundName}</strong>{" "}
              & portfolio: <strong>{portfolioName}</strong>.
            </>
          ) : (
            <>
              We are currently extracting information from the file{" "}
              <strong>{fileName}</strong> for the <strong>{fundName}</strong>{" "}
              fund.
            </>
          )}
          <br />
          You will receive an update at <strong>{email}</strong> once the
          extraction process is completed.
        </p>
        <div style={buttonContainerStyle}>
          <button
            style={uploadAnotherButtonStyle}
            onClick={handleUploadAnother}
          >
            OK
          </button>

          {
            isTool && (
                  <>
                    <button
                        style={moveToWorkflowButtonStyle}
                        onClick={handleNextPage}
                    >
                      Proceed to Update Data
                    </button>
                  </>
              )
          }


          {
              !isTool && (
                  <>
                    <button
                        style={moveToWorkflowButtonStyle}
                        onClick={handleMoveToWorkflow}
                    >
                      Go to Workflow
                    </button>
                    <button style={moveToWorkflowButtonStyle} onClick={handleNextPage}>
                      Proceed to Update Page
                    </button>
                  </>
              )
          }


        </div>
      </div>
    </div>
  );
};

export default Modal;
