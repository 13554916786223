import {FormControl, Grid, InputLabel, MenuItem, Select, Typography, Divider as MuiDivider} from "@mui/material";
import React, {useEffect, useState} from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import {toast} from "react-toastify";
import {generateYears} from "../../utils/dateUtil";
import styled from "@emotion/styled";
import {spacing} from "@mui/system";


const Divider = styled(MuiDivider)(spacing);

const XlsExportAll = () => {

    const [years, setYears] = useState<number[]>([]);
    const quarters = [
        { name: "Q1", value: "31-03" },
        { name: "Q2", value: "30-06" },
        { name: "Q3", value: "30-09" },
        { name: "Q4", value: "31-12" },
    ];
    const [selectedQuarter, setSelectedQuarter] = useState<string>("");
    const [selectedYear, setSelectedYear] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);

    const exportData = async () => {
        if (!selectedQuarter || !selectedYear) {
            alert("Please select a quarter and year to proceed.");
            return;
        } else {
            setIsLoading(true);

            await axiosInstance.post("/exportData/exportAll", {
                quarter: selectedQuarter,
                year: selectedYear,
            }, {
                responseType: 'blob'
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                const reportingDate = `${selectedQuarter}-${selectedYear}`;
                link.setAttribute('download', `${reportingDate}-all-data.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
                toast.success("Data Exported Successfully");
                setIsLoading(false);
            }).catch(error => {
                toast.error("Sorry, data export failed. Please try again");
                setIsLoading(false);
            });
        }
    };

    const handleQuarterChange = (event: any) => {
        setSelectedQuarter(event.target.value);
    };
    const handleYearChange = (event: any) => {
        setSelectedYear(event.target.value);
    };

    useEffect(() => {
        const dynamicYears = generateYears(2000);
        setYears(dynamicYears);
    }, []);

    return (
        <div style={{alignContent: "center", alignSelf: "center"}}>
            <Typography variant="h3">XLS Export All</Typography>
            <Divider my={6} />

            <Typography
                style={{
                    textAlign: "justify",
                    marginBottom: "50px",
                    width: "80%"
                }}
            >
                This tool downloads all the  collections from database to xls format.
            </Typography>

            <div
                style={{
                    alignContent: "center",
                    alignItems: "center",
                    marginLeft: "20px"
                }}
            >
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                            <Typography
                                variant="subtitle2"
                                fontSize="15px"
                                style={{
                                    padding: "10px",
                                }}
                            >
                                Select reporting quarter and year:
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Quarter</InputLabel>
                                        <Select
                                            value={selectedQuarter || ''}
                                            onChange={handleQuarterChange}
                                            label="Quarter"
                                        >
                                            {quarters.map((quarter) => (
                                                <MenuItem key={quarter.name} value={quarter.value}>
                                                    {quarter.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {/* Year Selector */}
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Year</InputLabel>
                                        <Select
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 300, // Adjust max height to your preference
                                                    },
                                                },
                                            }}
                                            value={selectedYear || ''}
                                            onChange={handleYearChange}
                                            label="Year"
                                        >
                                            {years.map((year) => (
                                                <MenuItem key={year} value={year}>
                                                    {year}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <button
                                            onClick={exportData}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#007bff",
                                                color: "white",
                                                border: "none",
                                                padding: "12px 24px",
                                                borderRadius: "40px",
                                                height: "48px",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                transition: "transform 0.2s, box-shadow 0.2s",
                                                cursor: "pointer",
                                                outline: "none",
                                                opacity: isLoading ? 0.8 : 1,
                                            }}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? "Processing..." : "Export Data"}
                                        </button>

                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </div>

            </div>
        </div>
    )
}

export default XlsExportAll;
