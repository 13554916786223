import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import XlsExports from "../../../components/tools/xlsExports";
import DDQ from "./PDF_Excel/Pdf_Excel";
import {
  ExcelSheetSVG,
  MultipleFileSVG,
  PDFSVG,
} from "../../../components/Svgs";
import MultiFileProcessing from "../../../components/tools/multi-file-processing";
import XlsExportAll from "../../../components/tools/xlsExportAll";
import PortfolioReviewAIPage from "../../../components/tools/portfolio_review_AI";
import PDFSummrization from "../../../components/tools/Pdf_summary";
import LPReportAnalysis from "../../../components/tools/LP_report_snslysis";
import CapitalCallTool from "../../../components/tools/capital_call/capitalCallTool";
import DistributionTool from "../../../components/tools/distribution/distributionTool";
import InvestmentAnalysis from "../../../components/tools/Investment_analysis";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Tools = () => {
  const [currentTool, setCurrentTool] = useState<number>(0);
  const tools = [
    {
      id: 1,
      name: "PDF To Excel",
      icon: <ExcelSheetSVG />,
    },
    {
      id: 2,
      name: "Multi vehicle funds (Financial Statements)",
      icon: <MultipleFileSVG />,
    },
    {
      id: 3,
      name: "XLS export TC Raw",
      icon: <ExcelSheetSVG />,
    },
    {
      id: 4,
      name: "XLS Export All",
      icon: <ExcelSheetSVG />,
    },
    {
      id: 5,
      name: "Quarterly Updates",
      icon: <PDFSVG />,
    },
    {
      id: 6,
      name: "PDF Summarization",
      icon: <PDFSVG />,
    },
    {
      id: 7,
      name: "Capital Call",
      icon: <PDFSVG />,
    },
    {
      id: 8,
      name: "Distribution",
      icon: <PDFSVG />,
    },

    // {
    //   id: 7,
    //   name: "Report Analysis",
    //   icon: <ExcelSheetSVG />,
    // },
  ];
  if (process.env.REACT_APP_NAME === "teralys") {
    tools.push(
      {
        id: 9,
        name: "LP Data Ingestion",
        icon: <ExcelSheetSVG />,
      },
      {
        id: 10,
        name: "Investment Analysis",
        icon: <PDFSVG />,
      }
    );
  }
  return (
    <React.Fragment>
      <Helmet title="Tools" />
      <Typography variant="h3" gutterBottom display="inline">
        Tools
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Tools</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  marginBottom: "60px",
                  cursor: "pointer",
                }}
              >
                {tools.map((tool) => {
                  return (
                    <div
                      key={tool.id}
                      onClick={() => {
                        setCurrentTool(tool.id);
                      }}
                      style={{
                        width: "265px",
                        height: "110px",
                        fontSize: "1.1rem",
                        border: "3px solid",
                        marginRight: "20px",
                        marginTop: "20px",
                        backgroundColor: "#1B2430",
                        color: "#fff",
                        fontWeight: "bold",
                        borderColor:
                          tool.id === currentTool ? "#C5585C" : "transparent",
                        opacity: tool.id === currentTool ? "1" : "0.5",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          width: "100%",
                          padding: "6px 10px",
                        }}
                      >
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            minWidth: "40px",
                            borderRadius: "50%",
                            fontWeight: "bold",
                            backgroundColor: "#eee",
                            color: "#333",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignSelf: "center",
                          }}
                        >
                          <div style={{ marginTop: "5px", width: "25px" }}>
                            {tool.icon}
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            textWrap: "wrap",
                            marginLeft: "10px",
                          }}
                        >
                          {tool.name}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {currentTool === 1 && <DDQ />}
              {currentTool === 2 && <MultiFileProcessing />}
              {currentTool === 3 && <XlsExports />}
              {currentTool === 4 && <XlsExportAll />}
              {currentTool === 5 && <PortfolioReviewAIPage />}
              {currentTool === 6 && <PDFSummrization />}
              {currentTool === 7 && <CapitalCallTool />}
              {currentTool === 8 && <DistributionTool />}
              {/* {currentTool === 7 && <ReportAnalysis />} */}
              {currentTool === 9 && <LPReportAnalysis />}
              {currentTool === 10 && <InvestmentAnalysis />}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Tools;
