import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify"; // Import ToastContainer
import "react-toastify/dist/ReactToastify.css";
import {AppInsightsContext, ReactPlugin} from "@microsoft/applicationinsights-react-js";
import {ApplicationInsights} from "@microsoft/applicationinsights-web";
const clientSideEmotionCache = createEmotionCache();

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({ config: {
    connectionString: process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  } });

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_NAME === "teralys") {
    appInsights.loadAppInsights();
    appInsights.trackPageView();
}

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const { theme } = useTheme();

  return (
    <CacheProvider value={emotionCache}>
      <AppInsightsContext.Provider value={reactPlugin}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | AltQ"
          defaultTitle="AltQ - A React Reporting App"
        />
        <Provider store={store}>
          {/* @ts-ignore */}

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiThemeProvider theme={createTheme(theme)}>
              <ToastContainer />
              {content}
            </MuiThemeProvider>
          </LocalizationProvider>
        </Provider>
      </HelmetProvider>
      </AppInsightsContext.Provider>
    </CacheProvider>
  );
}

export default App;
