export const baseURL = process.env.REACT_APP_API_URL;

export const fileUrl =
  typeof window !== "undefined" && window.location.href.includes("localhost")
    ? "http://localhost:8001/files/"
    : `${process.env.REACT_APP_API_URL}/files/`;

export const emailUrl =
  typeof window !== "undefined" && window.location.href.includes("localhost")
    ? "http://localhost:3000"
    : `${process.env.REACT_APP_URL}`;
