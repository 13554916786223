export interface marketWatchUS {
_id:  number,
Reporting_Year:  string,
dealvalueUS_Software:  number,
dealvalueUS_Pharma_Biotech:  number,
dealvalueUS_Other:  number,
dealvalueUS_Media:  number,
dealvalueUS_IT_Hardware:  number,
dealvalueUS_HC_Services_Systems:  number,
dealvalueUS_HC_Devices_Supplies:  number,
dealvalueUS_Energy:  number,
dealvalueUS_Consumer_Goods_Recreation:  number,
dealvalueUS_Commercial_Services:  number,
dealvalueUS_Transportation:  number,
dealvalueUS_Total_US_M:  number,
dealvalueUS_Life_Sciences_Deal_Value_B:  number,
dealvalueUS_Life_Sciences_as_Percent_of_Total_US_VC:  number,
dealvalueUS_IT_M:  number,
dealvalueUS_LS_M:  number,
dealvalueUS_CT_M:  number,
dealvalueUS_TRAD_M:  number,
dealcountUS_Software:  number,
dealcountUS_Pharma_Biotech:  number,
dealcountUS_Other:  number,
dealcountUS_Media:  number,
dealcountUS_IT_Hardware:  number,
dealcountUS_HC_Services_Systems:  number,
dealcountUS_HC_Devices_Supplies:  number,
dealcountUS_Energy:  number,
dealcountUS_Consumer_Goods_Recreation:  number,
dealcountUS_Commercial_Services:  number,
dealcountUS_Transportation:  number,
dealcountUS_Total_US:  number,
dealcountUS_Life_Sciences_Deal_Count:  number,
dealcountUS_Life_Sciences_as_Percent_of_Total_US_VC:  number,
dealcountUS_Number_IT:  number,
dealcountUS_Number_LS:  number,
dealcountUS_Number_CT:  number,
dealcountUS_Number_TRAD:  number,
frUS_RaisedbyFunds:  number,
frUS_Funds:  number,
exitcountUS_Acquisition:  number,
exitcountUS_IPO:  number,
exitcountUS_Buyout_Count:  number,
exitvalueUS_Acquisition:  number,
exitvalueUS_IPO:  number,
exitvalueUS_Buyout_Count:  number,
frsizeUS_Under_$50M:  number,
frsizeUS_$50M_$100M:  number,
frsizeUS_$100M_$250M:  number,
frsizeUS_$250M_$500M:  number,
frsizeUS_$500M_$1B:  number,
frsizeUS_$1B:  number,
frsizeUS_Undisclosed:  number,
}
export const fieldNames: { [key in keyof marketWatchUS]: string } = {
  _id: "ID",
  Reporting_Year: "Reporting Year",
  dealvalueUS_Software: "Deal Value US Software",
  dealvalueUS_Pharma_Biotech: "Deal Value US Pharma Biotech",
  dealvalueUS_Other: "Deal Value US Other",
  dealvalueUS_Media: "Deal Value US Media",
  dealvalueUS_IT_Hardware: "Deal Value US IT Hardware",
  dealvalueUS_HC_Services_Systems: "Deal Value US HC Services Systems",
  dealvalueUS_HC_Devices_Supplies: "Deal Value US HC Devices Supplies",
  dealvalueUS_Energy: "Deal Value US Energy",
  dealvalueUS_Consumer_Goods_Recreation: "Deal Value US Consumer Goods Recreation",
  dealvalueUS_Commercial_Services: "Deal Value US Commercial Services",
  dealvalueUS_Transportation: "Deal Value US Transportation",
  dealvalueUS_Total_US_M: "Deal Value US Total US M",
  dealvalueUS_Life_Sciences_Deal_Value_B: "Deal Value US Life Sciences Deal Value B",
  dealvalueUS_Life_Sciences_as_Percent_of_Total_US_VC: "Deal Value US Life Sciences as Percent of Total US VC",
  dealvalueUS_IT_M: "Deal Value US IT M",
  dealvalueUS_LS_M: "Deal Value US LS M",
  dealvalueUS_CT_M: "Deal Value US CT M",
  dealvalueUS_TRAD_M: "Deal Value US TRAD M",
  dealcountUS_Software: "Deal Count US Software",
  dealcountUS_Pharma_Biotech: "Deal Count US Pharma Biotech",
  dealcountUS_Other: "Deal Count US Other",
  dealcountUS_Media: "Deal Count US Media",
  dealcountUS_IT_Hardware: "Deal Count US IT Hardware",
  dealcountUS_HC_Services_Systems: "Deal Count US HC Services Systems",
  dealcountUS_HC_Devices_Supplies: "Deal Count US HC Devices Supplies",
  dealcountUS_Energy: "Deal Count US Energy",
  dealcountUS_Consumer_Goods_Recreation: "Deal Count US Consumer Goods Recreation",
  dealcountUS_Commercial_Services: "Deal Count US Commercial Services",
  dealcountUS_Transportation: "Deal Count US Transportation",
  dealcountUS_Total_US: "Deal Count US Total US",
  dealcountUS_Life_Sciences_Deal_Count: "Deal Count US Life Sciences Deal Count",
  dealcountUS_Life_Sciences_as_Percent_of_Total_US_VC: "Deal Count US Life Sciences as Percent of Total US VC",
  dealcountUS_Number_IT: "Deal Count US Number IT",
  dealcountUS_Number_LS: "Deal Count US Number LS",
  dealcountUS_Number_CT: "Deal Count US Number CT",
  dealcountUS_Number_TRAD: "Deal Count US Number TRAD",
  frUS_RaisedbyFunds: "FR US Raised by Funds",
  frUS_Funds: "FR US Funds",
  exitcountUS_Acquisition: "Exit Count US Acquisition",
  exitcountUS_IPO: "Exit Count US IPO",
  exitcountUS_Buyout_Count: "Exit Count US Buyout Count",
  frsizeUS_Under_$50M: "FR Size US Under $50M",
  frsizeUS_$50M_$100M: "FR Size US $50M $100M",
  frsizeUS_$100M_$250M: "FR Size US $100M $250M",
  frsizeUS_$500M_$1B: "FR Size US $500M $1B",
  frsizeUS_$250M_$500M: "FR Size US $250M $500M",
  frsizeUS_$1B: "FR Size US $1B",
  frsizeUS_Undisclosed: "FR Size US",
  exitvalueUS_Acquisition: "",
  exitvalueUS_IPO: "",
  exitvalueUS_Buyout_Count: ""
};
export const orderArray: (keyof marketWatchUS)[] = [
  "_id",
  "Reporting_Year",
  "dealvalueUS_Software",
  "dealvalueUS_Pharma_Biotech",
  "dealvalueUS_Other",
  "dealvalueUS_Media",
  "dealvalueUS_IT_Hardware",
  "dealvalueUS_HC_Services_Systems",
  "dealvalueUS_HC_Devices_Supplies",
  "dealvalueUS_Energy",
  "dealvalueUS_Consumer_Goods_Recreation",
  "dealvalueUS_Commercial_Services",
  "dealvalueUS_Transportation",
  "dealvalueUS_Total_US_M",
  "dealvalueUS_Life_Sciences_Deal_Value_B",
  "dealvalueUS_Life_Sciences_as_Percent_of_Total_US_VC",
  "dealvalueUS_IT_M",
  "dealvalueUS_LS_M",
  "dealvalueUS_CT_M",
  "dealvalueUS_TRAD_M",
  "dealcountUS_Software",
  "dealcountUS_Pharma_Biotech",
  "dealcountUS_Other",
  "dealcountUS_Media",
  "dealcountUS_IT_Hardware",
  "dealcountUS_HC_Services_Systems",
  "dealcountUS_HC_Devices_Supplies",
  "dealcountUS_Energy",
  "dealcountUS_Consumer_Goods_Recreation",
  "dealcountUS_Commercial_Services",
  "dealcountUS_Transportation",
  "dealcountUS_Total_US",
  "dealcountUS_Life_Sciences_Deal_Count",
  "dealcountUS_Life_Sciences_as_Percent_of_Total_US_VC",
  "dealcountUS_Number_IT",
  "dealcountUS_Number_LS",
  "dealcountUS_Number_CT",
  "dealcountUS_Number_TRAD",
  "frUS_RaisedbyFunds",
  "frUS_Funds",
  "exitcountUS_Acquisition",
  "exitcountUS_IPO",
  "exitcountUS_Buyout_Count",
  "frsizeUS_Under_$50M",
  "frsizeUS_$50M_$100M",
  "frsizeUS_$100M_$250M",
  "frsizeUS_$250M_$500M",
  "frsizeUS_$500M_$1B",
  "frsizeUS_$1B",
  "frsizeUS_Undisclosed",
];
