import { CSSProperties, ChangeEvent, useEffect, useState } from "react";
import { Question, TopUpdates } from "../../interfaces/aiDashboard.interface";
import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FundNewsDialog from "./fundNews";
import IndustryNewsBox from "./industryNews";
import QuestionAskedBox from "./QuestionAsked";
import ScoreIndicatorBox from "./scoreIndicator";

interface DynamicFormProps {
  fieldData: {
    [fieldName: string]: {
      type: string | Boolean;
      defaultValue: string;
      name: string;
    };
  };
  changedata: (formData: {
    [fieldName: string]:
      | string
      | undefined
      | Date
      | File
      | Boolean
      | TopUpdates[]
      | Question[];
  }) => void;
}
interface FormData {
  [fieldName: string]:
    | string
    | undefined
    | Date
    | File
    | Boolean
    | TopUpdates[]
    | Question[];
}
const DynamicFormAi: React.FC<DynamicFormProps> = ({
  fieldData,
  changedata,
}) => {
  useEffect(() => {
    const updatedFormState: FormData = {};
    setImageError(false);
    Object.keys(fieldData).forEach((fieldName) => {
      updatedFormState[fieldName] = fieldData[fieldName].defaultValue;
    });
    setNews(updatedFormState["fundUpdates"] as TopUpdates[]);
    setindustryNews(updatedFormState["IndustryUpdates"] as TopUpdates[]);
    setQuestionAsked(updatedFormState["questionAsked"] as Question[]);
    setScoreIndicator(updatedFormState["scoreIndicator"] as Question[]);
  }, [fieldData]);
  const initialFormState: FormData = {};
  Object.keys(fieldData).forEach((fieldName) => {
    initialFormState[fieldName] = fieldData[fieldName].defaultValue || "";
  });
  const handleFileChange = (event: any) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      setFormData({
        ...formData,
        [event.target.name]: file, // Store the File object
      });
    }
  };
  const [NewsManagmentDialog, setNewsManagmentDialog] = useState(false);
  const [IndustryNewsDialog, setIndustryNewsDialog] = useState(false);
  const [QuestionAskedDialog, setQuestionAskedDialog] = useState(false);
  const [scoreIndicatorDialog, setScoreIndicatorDialog] = useState(false);
  const [news, setNews] = useState<TopUpdates[]>([]);
  const [industryNews, setindustryNews] = useState<TopUpdates[]>([]);
  const [questionAsked, setQuestionAsked] = useState<Question[]>([]);
  const [scoreIndicator, setScoreIndicator] = useState<Question[]>([]);
  const handleOpensetNewsManagmentDialog = () => {
    setNewsManagmentDialog(true);
  };
  const handleCloseNewsManagmentDialog = () => {
    setNewsManagmentDialog(false);
  };
  const handleCloseIndustryNewsDialog = () => {
    setIndustryNewsDialog(false);
  };
  const handleOpenIndustryNewsDialog = () => {
    setIndustryNewsDialog(true);
  };
  const handleOpenQuestionAskedDialog = () => {
    setQuestionAskedDialog(true);
  };
  const handleCloseQuestionAskedDialog = () => {
    setQuestionAskedDialog(false);
  };
  const handleCloseScoreIndicatorDialogOpen = () => {
    setScoreIndicatorDialog(false);
  };
  const handleOpenScoreIndicatorDialogOpen = () => {
    setScoreIndicatorDialog(true);
  };

  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  const handleNewsChnage = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const updatedNews = [...(formData["fundUpdates"] as TopUpdates[])];
    const { name, value } = e.target;
    updatedNews[index] = {
      ...updatedNews[index],
      [name]: value,
    };
    const newNews = [...news];
    newNews[index] = {
      ...newNews[index],
      [e.target.name]: e.target.value,
    };
    setNews(newNews);
    setFormData({
      ...formData,
      fundUpdates: updatedNews,
    });
    setChangedFields({
      ...changedFields,
      fundUpdates: updatedNews,
    });
  };
  const handleAddNewNews = () => {
    const today = new Date();

    const newTeamMember: TopUpdates = {
      title: "",
      url: "",
      summary: "",
      publishedAt: today,
      content: "",
      Main_Company_Name: "",
    };
    setNews([
      ...news,
      {
        title: "",
        url: "",
        summary: "",
        publishedAt: today,
        content: "",
        Main_Company_Name: "",
      },
    ]);
    setFormData({
      ...formData,
      fundUpdates: [
        ...(formData["fundUpdates"] as TopUpdates[]),
        newTeamMember,
      ],
    });
  };
  const handleRemoveNews = (index: number) => {
    const updatedNews = [...(formData["fundUpdates"] as TopUpdates[])];
    updatedNews.splice(index, 1);
    const newNews = [...news];
    newNews.splice(index, 1);
    setNews(newNews);
    setFormData({
      ...formData,
      fundUpdates: updatedNews,
    });
    setChangedFields({
      ...changedFields,
      fundUpdates: updatedNews,
    });
  };
  const handleIndustryNewsChnage = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const updatedNews = [...(formData["IndustryUpdates"] as TopUpdates[])];
    const { name, value } = e.target;
    updatedNews[index] = {
      ...updatedNews[index],
      [name]: value,
    };
    const newNews = [...industryNews];
    newNews[index] = {
      ...newNews[index],
      [e.target.name]: e.target.value,
    };
    setindustryNews(newNews);
    setFormData({
      ...formData,
      IndustryUpdates: updatedNews,
    });
    setChangedFields({
      ...changedFields,
      IndustryUpdates: updatedNews,
    });
  };
  const handleAddNewIndustryNews = () => {
    const today = new Date();

    const newNews: TopUpdates = {
      title: "",
      url: "",
      summary: "",
      publishedAt: today,
      content: "",
      Main_Company_Name: "",
    };
    setindustryNews([
      ...industryNews,
      {
        title: "",
        url: "",
        summary: "",
        publishedAt: today,
        content: "",
        Main_Company_Name: "",
      },
    ]);
    setFormData({
      ...formData,
      IndustryUpdates: [...(formData["industryNews"] as TopUpdates[]), newNews],
    });
  };
  const handleRemoveIndustryNews = (index: number) => {
    const updatedNews = [...(formData["IndustryUpdates"] as TopUpdates[])];
    updatedNews.splice(index, 1);
    const newNews = [...industryNews];
    newNews.splice(index, 1);
    setindustryNews(newNews);
    setFormData({
      ...formData,
      IndustryUpdates: updatedNews,
    });
    setChangedFields({
      ...changedFields,
      IndustryUpdates: updatedNews,
    });
  };
  const handleQuestionAskedChnage = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const updatedNews = [...(formData["questionAsked"] as Question[])];
    const { name, value } = e.target;
    updatedNews[index] = {
      ...updatedNews[index],
      [name]: value,
    };
    const newNews = [...questionAsked];
    newNews[index] = {
      ...newNews[index],
      [e.target.name]: e.target.value,
    };
    setQuestionAsked(newNews);
    setFormData({
      ...formData,
      questionAsked: updatedNews,
    });
    setChangedFields({
      ...changedFields,
      questionAsked: updatedNews,
    });
  };
  const handleAddNewQuestion = () => {
    const newNews: Question = {
      title: "",
      url: "",
    };
    setQuestionAsked([
      ...questionAsked,
      {
        title: "",
        url: "",
      },
    ]);
    setFormData({
      ...formData,
      questionAsked: [...(formData["questionAsked"] as Question[]), newNews],
    });
  };
  const handleRemoveQuestion = (index: number) => {
    const updatedNews = [...(formData["questionAsked"] as Question[])];
    updatedNews.splice(index, 1);
    const newNews = [...questionAsked];
    newNews.splice(index, 1);
    setQuestionAsked(newNews);
    setFormData({
      ...formData,
      questionAsked: updatedNews,
    });
    setChangedFields({
      ...changedFields,
      questionAsked: updatedNews,
    });
  };
  const handleScoreChnage = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const updatedNews = [...(formData["scoreIndicator"] as Question[])];
    const { name, value } = e.target;
    updatedNews[index] = {
      ...updatedNews[index],
      [name]: value,
    };
    const newNews = [...scoreIndicator];
    newNews[index] = {
      ...newNews[index],
      [e.target.name]: e.target.value,
    };
    setScoreIndicator(newNews);
    setFormData({
      ...formData,
      scoreIndicator: updatedNews,
    });
    setChangedFields({
      ...changedFields,
      scoreIndicator: updatedNews,
    });
  };
  const handleAddNewScore = () => {
    const newNews: Question = {
      title: "",
      url: "",
    };
    setScoreIndicator([
      ...scoreIndicator,
      {
        title: "",
        url: "",
      },
    ]);
    setFormData({
      ...formData,
      scoreIndicator: [...(formData["scoreIndicator"] as Question[]), newNews],
    });
  };
  const handleRemoveScore = (index: number) => {
    const updatedNews = [...(formData["scoreIndicator"] as Question[])];
    updatedNews.splice(index, 1);
    const newNews = [...scoreIndicator];
    newNews.splice(index, 1);
    setScoreIndicator(newNews);
    setFormData({
      ...formData,
      scoreIndicator: updatedNews,
    });
    setChangedFields({
      ...changedFields,
      scoreIndicator: updatedNews,
    });
  };

  const [formData, setFormData] = useState<FormData>(initialFormState);
  const [changedFields, setChangedFields] = useState<FormData>({});
  const styles: { [key: string]: CSSProperties } = {
    label: {
      fontWeight: "bold",
      padding: "10px",
      verticalAlign: "top",
    },
    label2: {
      fontWeight: "bold",
      padding: "5px 20px",
    },
    input: {
      verticalAlign: "text-top",
      padding: "10px",
      border: "1px solid #ccc",
      width: "200px",
      borderRadius: "4px", // Make input take full width of its cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    button2: {
      marginLeft: "20px",
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
  const handleSubmitForm2 = () => {
    if (formData["portfolio_companyLogo"] != null) {
      const data = {
        portfolio_companyLogo: formData["portfolio_companyLogo"],
        ...changedFields,
      };
      changedata(data);
      setChangedFields({});
    } else if (formData["fund_logo"] != null) {
      const data = {
        fund_logo: formData["fund_logo"],
        ...changedFields,
      };
      changedata(data);
      setChangedFields({});
    } else {
      changedata(changedFields);
      setChangedFields({});
    }
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, type, checked, value } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
    setChangedFields({
      ...changedFields,
      [name]: newValue,
    });
  };
  const form = React.createRef<HTMLFormElement>();

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div style={styles.buttonContainer}>
        <button
          type="button"
          style={styles.button2}
          onClick={handleSubmitForm2}
        >
          Update
        </button>
      </div>
      <form ref={form}>
        <table
          style={{
            width: "60%",
            borderCollapse: "collapse",
            borderSpacing: 0,
            marginTop: "20px",
          }}
        >
          <tbody>
            {Object.keys(fieldData)
              .filter(
                (key) =>
                  key !== "fundUpdates" &&
                  key !== "IndustryUpdates" &&
                  key !== "questionAsked" &&
                  key !== "scoreIndicator"
              )
              .map((fieldName, index, array) => {
                // Determine the name of the corresponding description field, if this is a checkbo

                const desc =
                  fieldName.includes("_description") ||
                  fieldName.includes("_url") ||
                  fieldName.includes("_curl");

                return (
                  <tr
                    key={fieldName}
                    style={{
                      paddingTop: "30px",
                      marginBottom: "20px",
                    }}
                  >
                    {!desc && (
                      <td style={{ width: "20%", ...styles.label }}>
                        {fieldData[fieldName].name}
                      </td>
                    )}
                    {fieldData[fieldName].type === "file" ? (
                      <>
                        <td style={{ width: "70%" }}>
                          <input
                            type="file"
                            id={fieldName}
                            name={fieldName}
                            onChange={handleFileChange}
                          />
                        </td>
                        <>
                          {fieldData[fieldName].defaultValue && !imageError ? (
                            <img
                              src={fieldData[fieldName].defaultValue as string}
                              width="100px"
                              height="100px"
                              onError={handleImageError}
                              alt="Dynamic Content"
                            />
                          ) : (
                            <p>No image available</p>
                          )}
                        </>
                      </>
                    ) : (
                      !desc && ( // Only render non-checkbox fields or descriptions without a preceding checkbox
                        <td style={{ width: "80%" }}>
                          <TextField
                            multiline
                            rows={String(formData[fieldName]).length / 60 + 1}
                            label={fieldData[fieldName].name}
                            variant="outlined"
                            style={{
                              textAlign: "justify",
                              width: "60%",
                              marginBottom: "10px",
                              background: "#fff", // Adds a white background
                              borderColor: "#007bff", // Blue border color
                              borderWidth: "2px", // Border width
                              borderRadius: "4px", // Rounded corners
                              padding: "5px", // Internal padding
                            }}
                            type={fieldData[fieldName].type as string}
                            id={index.toString()}
                            name={fieldName}
                            value={String(formData[fieldName]) || ""}
                            onChange={handleChange}
                            disabled={fieldName === "LP_name"}
                          />
                        </td>
                      )
                    )}
                  </tr>
                );
              })}
            <tr>
              <td style={{ height: "10px" }} colSpan={2}></td>
            </tr>
            <tr style={{ paddingTop: "50px" }}>
              <td style={{ width: "30%", ...styles.label }}>Top Fund News:</td>
              <td
                style={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleOpensetNewsManagmentDialog}
                >
                  Update Fund News
                </Button>
              </td>
            </tr>
            <tr>
              <td style={{ height: "10px" }} colSpan={2}></td>
            </tr>
            <tr style={{ paddingTop: "50px" }}>
              <td style={{ width: "30%", ...styles.label }}>
                Top Industry News
              </td>
              <td
                style={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleOpenIndustryNewsDialog}
                >
                  Update Industry News
                </Button>
              </td>
            </tr>
            <tr>
              <td style={{ height: "10px" }} colSpan={2}></td>
            </tr>
            <tr style={{ paddingTop: "50px" }}>
              <td style={{ width: "30%", ...styles.label }}>
                Top Score Indicator
              </td>
              <td
                style={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleOpenScoreIndicatorDialogOpen}
                >
                  Update Score Indicator
                </Button>
              </td>
            </tr>
            <tr>
              <td style={{ height: "10px" }} colSpan={2}></td>
            </tr>
            <tr style={{ paddingTop: "50px" }}>
              <td style={{ width: "30%", ...styles.label }}>
                Top Question Asked
              </td>
              <td
                style={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleOpenQuestionAskedDialog}
                >
                  Update Question Asked
                </Button>
              </td>
            </tr>
            <FundNewsDialog
              open={NewsManagmentDialog}
              onClose={handleCloseNewsManagmentDialog}
              fundUpdates={news}
              onNewsChange={handleNewsChnage}
              onAddNews={handleAddNewNews}
              onRemoveNews={handleRemoveNews}
            />
            <IndustryNewsBox
              open={IndustryNewsDialog}
              onClose={handleCloseIndustryNewsDialog}
              industryUpdates={industryNews}
              onNewsChange={handleIndustryNewsChnage}
              onAddNews={handleAddNewIndustryNews}
              onRemoveNews={handleRemoveIndustryNews}
            />
            <ScoreIndicatorBox
              open={scoreIndicatorDialog}
              onClose={handleCloseScoreIndicatorDialogOpen}
              scoreIndicator={scoreIndicator}
              onscoreIndicatorChange={handleScoreChnage}
              onAddScore={handleAddNewScore}
              onRemoveScore={handleRemoveScore}
            />
            <QuestionAskedBox
              open={QuestionAskedDialog}
              onClose={handleCloseQuestionAskedDialog}
              questionAsked={questionAsked}
              onQuestionAskedChnage={handleQuestionAskedChnage}
              onAddQuestion={handleAddNewQuestion}
              onRemoveQuestion={handleRemoveQuestion}
            />
          </tbody>
        </table>
      </form>
    </div>
  );
};
export default DynamicFormAi;
