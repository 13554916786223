import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const TruncateText = ({
  text,
  maxWords,
}: {
  text: string | number;
  maxWords: number;
}) => {
  const [open, setOpen] = useState(false);

  const textStr = text != null ? text.toString() : ""; // Check for null or undefined
  const words = textStr.split(" ");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {words.length > maxWords ? (
        <>
          {words.slice(0, maxWords).join(" ")}...{" "}
          <Button
            onClick={handleClickOpen}
            style={{
              textTransform: "none", // Keep the text as is
              padding: 0, // Remove padding
              minHeight: 0, // Remove minHeight
              minWidth: 0, // Remove minWidth
              color: "blue", // Set text color to blue
              fontSize: "inherit", // Inherit font size from parent
              lineHeight: "inherit", // Inherit line height from parent
            }}
          >
            read more
          </Button>
        </>
      ) : (
        textStr
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Full Text</DialogTitle>
        <DialogContent>
          <p>{textStr}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TruncateText;
