import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface PortfolioSelection {
  fundNames: Record<string, number>[];
  portfolioNames: Record<string, number>[];
  onSelectionChange: (data: {
    fund_ID: string;
    company_ID: string;
    date: string;
  }) => void;
}

const quarters = [
  { name: "Q1", value: "31-03" },
  { name: "Q2", value: "30-06" },
  { name: "Q3", value: "30-09" },
  { name: "Q4", value: "31-12" },
];

const years = Array.from(
  { length: 15 },
  (_, i) => new Date().getFullYear() - i
);

const PortfolioSelectionComponent: React.FC<PortfolioSelection> = ({
  fundNames,
  portfolioNames,
  onSelectionChange,
}) => {
  const [selectedFundName, setSelectedFundName] = useState("");
  const [selectedFundId, setSelectedFundId] = useState("");
  const [selectedPortfolioName, setSelectedPortfolioName] = useState("");
  const [selectedPortfolioID, setSelectedPortfolioID] = useState("");
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [selectedYear, setSelectedYear] = useState<any>("");

  useEffect(() => {
    if (portfolioNames.length > 0) {
      setSelectedPortfolioName(Object.keys(portfolioNames[0])[0]);
      setSelectedPortfolioID(Object.values(portfolioNames[0])[0].toString());
    }
  }, [portfolioNames]);
  useEffect(() => {
    if (fundNames.length > 0) {
      const initialFund = fundNames[0];
      setSelectedFundName(Object.keys(initialFund)[0]);
      setSelectedFundId(Object.values(initialFund)[0].toString());
    }
  }, [fundNames]);

  useEffect(() => {
    onSelectionChange({
      fund_ID: selectedFundId,
      company_ID: selectedPortfolioID,
      date: `${selectedQuarter}-${selectedYear}`,
    });
  }, [selectedFundId, selectedPortfolioID, selectedQuarter, selectedYear]);

  return (
    <>
      <Box display="flex" alignItems="center" padding="10px">
        <div>
          <label
            style={{
              marginRight: "10px",
              marginTop: "10px",
              fontWeight: "bold",
            }}
          >
            Select Fund :
          </label>
          <select
            id="fundSelection"
            onChange={(e) => {
              setSelectedFundName(
                Object.keys(fundNames[e.target.selectedIndex]).toString()
              );
              setSelectedFundId(e.target.value);
            }}
            style={{
              backgroundColor: "#f2f2f2",
              color: "#333",
              padding: "10px",
              borderRadius: "5px",
              outline: "none",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
              width: "200px",
              cursor: "pointer",
              height: "52px",
              marginTop: "20px",
            }}
          >
            {fundNames.map((fund, index) => {
              const fundName = Object.keys(fund)[0];
              const fundID = fund[fundName];
              return (
                <option key={index} value={fundID}>
                  {fundName}
                </option>
              );
            })}
          </select>
          <label
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              marginTop: "10px",
              fontWeight: "bold",
            }}
          >
            Select Portfolio :
          </label>
          <select
            id="portfolioSelection"
            value={selectedPortfolioID}
            onChange={(e) => {
              setSelectedPortfolioName(
                Object.keys(portfolioNames[e.target.selectedIndex]).toString()
              );
              setSelectedPortfolioID(e.target.value);
            }}
            style={{
              backgroundColor: "#f2f2f2",
              color: "#333",
              padding: "10px",
              borderRadius: "5px",
              outline: "none",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
              width: "200px",
              cursor: "pointer",
              height: "52px",
            }}
          >
            {portfolioNames.map((portfolio, index) => {
              const portfolioName = Object.keys(portfolio)[0];
              const company_ID = portfolio[portfolioName];
              return (
                <option key={index} value={company_ID}>
                  {portfolioName}
                </option>
              );
            })}
          </select>
        </div>
      </Box>
      <div
        style={{
          marginLeft: "20px",
          marginTop: "20px",
          marginBottom: "25px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="subtitle2"
          fontSize="15px"
          style={{ padding: "10px" }}
        >
          Select reporting quarter and year:
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Quarter</InputLabel>
              <Select
                value={selectedQuarter}
                onChange={(e) => setSelectedQuarter(e.target.value as string)}
                label="Quarter"
              >
                {quarters.map((quarter) => (
                  <MenuItem key={quarter.name} value={quarter.value}>
                    {quarter.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Year</InputLabel>
              <Select
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value as number)}
                label="Year"
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PortfolioSelectionComponent;
