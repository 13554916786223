import React, { useState, useEffect } from "react";
import axiosInstance from "../../../utils/axios/axios.instance";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { generateYears } from "../../../utils/dateUtil";
import FileInput from "../../../components/tools/fileInput";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

function AddLogo() {
  const [files, setFiles] = useState<(File | null)[]>([]);
  const [comment, setComment] = useState("");
  const [years, setYears] = useState<number[]>([]);
  const [rows, setRows] = useState<number[]>([0]);
  const quarters = [
    { name: "Q1", value: "03-31" },
    { name: "Q2", value: "06-30" },
    { name: "Q3", value: "09-30" },
    { name: "Q4", value: "12-31" },
  ];
  const [fundNames, setFundNames] = useState([]);
  const [selectedFundNames, setSelectedFundNames] = useState<string[]>(
    new Array(rows.length).fill("")
  );
  const [selectedQuarters, setSelectedQuarters] = useState<string[]>(
    new Array(rows.length).fill("")
  );
  const [selectedYears, setSelectedYears] = useState<string[]>(
    new Array(rows.length).fill("")
  );
  const [isLoading, setIsLoading] = useState(false);

  const uploadFiles = async () => {
    const areAllRowsFilled = selectedFundNames.every((fundName, index) => {
      const correspondingQuarter = selectedQuarters[index];
      const correspondingYear = selectedYears[index];
      const correspondingFile = files[index];
      return (
        fundName &&
        correspondingQuarter &&
        correspondingYear &&
        correspondingFile
      );
    });

    if (!areAllRowsFilled) {
      alert("Please fill all rows completely");
      return;
    } else {
      setIsLoading(true);
      const formData = new FormData();
      selectedFundNames.forEach((fundName, index) => {
        formData.append(`fundNames`, fundName);
      });

      selectedQuarters.forEach((quarter, index) => {
        const year = selectedYears[index];
        formData.append(`dates`, `${year}-${quarter}`);
      });
      formData.append("date", selectedYears[0] + "-" + selectedQuarters[0]);
      // files.forEach((file, index) => {
      //     formData.append(`files`, file!);
      // });
      formData.append("file", files[0]!);
      let time = new Date().toISOString();
      time = time.replace(/:/g, "-");

      const response = await axiosInstance.post("/uploadPdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        params: {
          timestamp: time,
        },
      });
      setIsLoading(false);
      if (response.status === 200) {
        toast.success("Files Uploaded Successfully");
        setTimeout(() => {
          setComment("");
        }, 5000);

        setIsLoading(false);
      } else {
        toast.error("Sorry, Failed to upload files. Please try again");
        setIsLoading(false);
      }
    }
  };

  const handleFileChange = (index: number, file: File | null) => {
    const newFiles = [...files];
    newFiles[index] = file;
    setFiles(newFiles);
  };

  const getFundNames = async () => {
    const response = await axiosInstance.get("/fund-names");
    const responseData = response.data.data;
    responseData.sort((a: { Fund_Name: string }, b: { Fund_Name: string }) => {
      return a.Fund_Name.toLowerCase().localeCompare(b.Fund_Name.toLowerCase());
    });
    setFundNames(responseData);
  };
  const handleAddNewRow = () => {
    setRows([...rows, rows[rows.length - 1] + 1]);
  };

  const handleFundNameChange = (index: number, newFundName: string) => {
    const newFundNames = [...selectedFundNames];
    newFundNames[index] = newFundName;
    setSelectedFundNames(newFundNames);
  };

  const handleQuarterChange = (index: number, newQuarter: string) => {
    const newQuarters = [...selectedQuarters];
    newQuarters[index] = newQuarter;
    setSelectedQuarters(newQuarters);
  };

  const handleYearChange = (index: number, newYear: string) => {
    const newYears = [...selectedYears];
    newYears[index] = newYear;
    setSelectedYears(newYears);
  };

  useEffect(() => {
    const dynamicYears = generateYears(2000);
    setYears(dynamicYears);
    getFundNames();
  }, []);
  return (
    <div style={{ alignContent: "center", alignSelf: "centers" }}>
      <div
        style={{
          alignContent: "center",
          alignItems: "center",
          marginLeft: "20px",
        }}
      >
        {rows.map((row, index) => {
          return (
            <div key={row}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Typography
                    variant="subtitle2"
                    fontSize="15px"
                    style={{
                      padding: "10px",
                    }}
                  >
                    Select fund name:
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel>Funds</InputLabel>
                    <Select
                      value={selectedFundNames[row] || ""}
                      onChange={(e) =>
                        handleFundNameChange(row, e.target.value)
                      }
                      label="Funds"
                    >
                      {fundNames.map((fund) => (
                        <MenuItem
                          key={fund["Fund_Name"]}
                          value={fund["fund_ID"]}
                        >
                          {fund["Fund_Name"]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <Typography
                    variant="subtitle2"
                    fontSize="15px"
                    style={{
                      padding: "10px",
                    }}
                  >
                    Select reporting quarter and year:
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel>Quarter</InputLabel>
                        <Select
                          value={selectedQuarters[row] || ""}
                          onChange={(e) =>
                            handleQuarterChange(row, e.target.value)
                          }
                          label="Quarter"
                        >
                          {quarters.map((quarter) => (
                            <MenuItem key={quarter.name} value={quarter.value}>
                              {quarter.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Year Selector */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel>Year</InputLabel>
                        <Select
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 300, // Adjust max height to your preference
                              },
                            },
                          }}
                          value={selectedYears[row] || ""}
                          onChange={(e) =>
                            handleYearChange(row, e.target.value)
                          }
                          label="Year"
                        >
                          {years.map((year) => (
                            <MenuItem key={year} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FileInput
                    id={`file_input_${row}`}
                    label={"Report"}
                    onFileChange={(file) => handleFileChange(row, file)}
                    material={true}
                  />
                </Grid>
              </Grid>
            </div>
          );
        })}

        {/*<div*/}
        {/*  style={{*/}
        {/*    marginTop: "50px",*/}
        {/*    border: "2px dashed gray",*/}
        {/*    padding: "20px",*/}
        {/*    cursor: "pointer",*/}
        {/*    textAlign: "center",*/}
        {/*  }}*/}
        {/*  onDragOver={(event) => event.preventDefault()}*/}
        {/*  onDrop={onDrop}*/}
        {/*  onClick={() => inputFileRef.current?.click()} // 2. Use the ref to click the input*/}
        {/*>*/}
        {/*  {file ? file.name : "Drag & Drop or Click to Select a File"}*/}
        {/*  <input*/}
        {/*    type="file"*/}
        {/*    style={{ display: "none" }}*/}
        {/*    onChange={onFileChange}*/}
        {/*    onClick={(e: any) => (e.target.value = "")}*/}
        {/*    ref={inputFileRef} // Attach the ref to the input*/}
        {/*  />*/}
        {/*</div>*/}

        {rows.length < 10 && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              onClick={handleAddNewRow}
              size="large"
              color="primary"
              aria-label="Add new company"
              style={{
                color: "#007bff",
              }}
            >
              <AddCircleOutlineIcon
                style={{
                  fontSize: "30px",
                }}
              />
            </IconButton>
          </Box>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            onClick={uploadFiles}
            style={{
              marginTop: "20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              padding: "12px 24px",
              borderRadius: "18px",
              height: "48px",
              fontSize: "16px",
              fontWeight: "500",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s, box-shadow 0.2s",
              cursor: "pointer",
              outline: "none",
              opacity: isLoading ? 0.8 : 1,
            }}
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "Process"}
          </button>
          {comment === "file uploaded" ? (
            <div
              style={{
                alignContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                marginBottom: "10%",
                color: "Green",
              }}
            >
              {" "}
              {comment}{" "}
            </div>
          ) : (
            <div
              style={{
                alignContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                marginBottom: "10%",
                color: "Red",
              }}
            >
              {" "}
              {comment}{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddLogo;
