export interface AIDashboard {
  Fund_Name: string;
  AQ_score: string;
  fundUpdates: TopUpdates[];
  IndustryUpdates: TopUpdates[];
  questionAsked: Question[];
  scoreIndicator: Question[];
}
export interface TopUpdates {
  publishedAt: Date;
  content: string;
  title: string;
  url: string;
  Main_Company_Name: string;
  summary: string;
}
export interface Question {
  title: string;
  url: string;
}
export const fieldNames: { [key in keyof AIDashboard]: string } = {
  Fund_Name: "Fund Names",
  AQ_score: "AQ score",
  fundUpdates: "fund Updates",
  IndustryUpdates: "Industry Updates",
  questionAsked: "Top Questions Asked",
  scoreIndicator: "Top score Indicator",
};
export const orderArray = [
  "Fund_Name",
  "AQ_score",
  "fundUpdates",
  "IndustryUpdates",
  "questionAsked",
  "scoreIndicator",
];
