import React, { CSSProperties, ChangeEvent } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import GridDeleteIcon from "@mui/icons-material/Delete";
import { IFundTrackRecord } from "../../interfaces/fundRaising.interface";

interface FundTrackRecordDialogProps {
  open: boolean;
  onClose: () => void;
  fundTrackRecords: IFundTrackRecord[];
  onFundTrackRecordChange: (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => void;
  onAddFundTrackRecord: () => void;
  onRemoveFundTrackRecord: (index: number) => void;
}

const FundTrackRecordDialog: React.FC<FundTrackRecordDialogProps> = ({
  open,
  onClose,
  fundTrackRecords,
  onFundTrackRecordChange,
  onAddFundTrackRecord,
  onRemoveFundTrackRecord,
}) => {
  const styles: { [key: string]: CSSProperties } = {
    label: {
      fontWeight: "bold",
      padding: "10px",
      width: "20px", // Align the text to the right within the label cell
    },
    label2: {
      fontWeight: "bold",
      padding: "5px 20px",
    },
    input: {
      marginTop: "3px",
      verticalAlign: "top",
      padding: "10px",
      border: "1px solid #ccc",
      width: "200px",
      borderRadius: "4px", // Make input take full width of its cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    button2: {
      marginLeft: "20px",
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Manage Fund Track Records</DialogTitle>
      <DialogContent>
        <table>
          <tbody>
            {fundTrackRecords.map((record, index) => (
              <React.Fragment key={index}>
                {/* Iterate over all fields in the IFundTrackRecord interface */}
                <tr>
                  <td style={styles.label}>Company:</td>
                  <td style={styles.cell}>
                    <input
                      type="text"
                      name="ftr_company"
                      value={record.ftr_company}
                      onChange={(e) => onFundTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                    <GridDeleteIcon
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                      onClick={() => onRemoveFundTrackRecord(index)}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.label}>Currency:</td>
                  <td>
                    <input
                      type="text"
                      name="ftr_currency"
                      value={record.ftr_currency}
                      onChange={(e) => onFundTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.label}>Region:</td>
                  <td>
                    <input
                      type="text"
                      name="ftr_region"
                      value={record.ftr_region}
                      onChange={(e) => onFundTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                  </td>
                </tr>
                {/* Repeat for each field in IFundTrackRecord */}
                <tr>
                  <td style={styles.label}>Investment Date:</td>
                  <td style={styles.cell}>
                    <input
                      type="date"
                      name="ftr_investmentDate"
                      value={record.ftr_investmentDate}
                      onChange={(e) => onFundTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.label}>Exit Date:</td>
                  <td>
                    <input
                      type="date"
                      name="ftr_exitDate"
                      value={record.ftr_exitDate}
                      onChange={(e) => onFundTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAddFundTrackRecord} color="primary">
          Add Track Record
        </Button>
        <Button onClick={onClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FundTrackRecordDialog;
