import styled from "@emotion/styled";
import LinearProgress, {linearProgressClasses} from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#007bff' : '#007bff',
    },
}));

const CustomProgressBar = ({ progress }: { progress: number }) => {
  return (
      <div style={{width: "100%", maxWidth: "300px", marginTop: "8px"}}>
          <span>Progress</span>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Box sx={{width: '100%', mr: 1}}>
                  <BorderLinearProgress variant="determinate" value={progress}/>
              </Box>
              <Box sx={{minWidth: 35}}>
                  <Typography variant="body2" color="text.secondary">{progress}%</Typography>
              </Box>
          </Box>
      </div>
  );
}

export default CustomProgressBar;
