export const KeyNames: { [key: string]: string } = {
  fund_logo: "Logo",
  fund_strategy: "Description",
  fund_teamSize: "Team Size",
  fund_highlights: "Highlights",
  fund_websiteURL: "Website URL",
  fund_focusCountry: "Focus Country",
  fund_historicalTer: "Historical TER",
  fund_ConcLim: "Concentration Limit",
  fund_Basis: "Basis",
  fund_Recycling: "Recycling",
  AQ_Score: "AQ Score",
  fund_called: "Fund Called",
  fund_teamBackground: "Team Background",
  fund_capitalInvested: "Capital Invested",
  Textboxes_QtrlyUpdate: "Qtrly Update",
  fund_Pref: "Preferred Return",
  fund_Carry: "Carry",
  fund_Textboxes_QtrlyreportUpdate: "Textbox Qtrly Update",
  fund_corporateActions: "Corporate Actions",
  fund_gpUpdates: "GP Updates",
  fund_riskDisclosure: "Risk Disclosure",
  fund_extraOrdinaryMovements: "Extraordinary Movements",
  fund_macroInductryDrivers: "Macro Industry Drivers",
  fund_keyDriversPreviousQTR: "Key Drivers Previous QTR",
  fund_financialSummary: "Financial Summary",
  fund_totalOtherAssetsLiabilities: "Total Other Assets Liabilities",
  fund_rvpi: "RVPI",
  Textboxes_ToBeRealized: "To Be Realized",
  AUC_GPDist: "AUC GP Distribution",
  AUC_GPNav: "AUC GP NAV",
  AUC_Reserves: "AUC Reserves",
  fund_partners: "Partners",
  fund_nav: "Net Asset Value",
  fund_dpi: "DPI",
  fund_tvpi: "TVPI",
  fund_capitalCalled: "Capital Called",
  fund_recallableDistributions: "Recallable Distributions",
  fund_netIRR: "Net IRR",
  fund_gpCarriedDistribution: "GP Carried Distribution",
  fund_distributed: "Fund Distributed",
  fund_IRR: "IRR",
  fund_currency: "Currency",
  fund_Fees: "Fund Fees",
  fund_Life_LPA: "Fund Life",
  Fund_Name: "Fund Name",
  fund_generalPartner: "General Partner",
  fund_investmentAdvisor: "Investment Advisor",
  fund_investmentManager: "Investment Manager",
  fund_fundSizeFinalClose: "Fund Size (Final Close)",
  fund_FirstClosing: "First Closing Date",
  fund_Life_Exp: "Fund Life expected",
  fund_commitment: "FoF Commitment",
  fund_gpCommitment: "GP Commitment",
  fund_legalStructure: "Legal Structure",
  fund_carriedInterest: "fund carried interest",
  fund_hurdleRate: "Hurdle rate",
  fund_clawBack: "GP Clawback",
  fund_removalWithCause: "Removal with Cause",
  fund_removalWithoutCause: "Removal without Cause",
  fund_treatmentOfCarreidInterestOnRemoval:
    "Treatment of Carried Interest on Removal",
  fund_overview: "Fund Overview",
  fund_botURL: "Fund Bot URL",
  lp_investmentStake: "LP Stake",
  LP_name: "LP Name",
  fund_active: "Active",
  fund_InvPer: "Investment Period",
  fund_PostInv: "Post Investment",
  fund_keyPersonProvision: "Key Person Provision",
  fund_governingLawAndJurisdiction: "Governing Law and Jurisdiction",
  fund_stage: "Fund Stage",
  fund_region: "Fund Region",
  Reporting_Date: "Reporting Date",
  fund_sectorFocus: "Sector Focus",
  fund_vintage: "Vintage",
  FTLA_InvPer: "Investment Period",
  fund_valueNetAsset: "Fund Net Asset Value",
  fund_expCalledRecalled: "Capital Called/Recalled",
  fund_percentageFundSizeLPA: "Percentage of Fund Size to LPA",
  fund_percentageNICLPA: "Net Invested Capital to LPA",
  fund_InvPer_ExpAct: "Investment Period vs Expected Actual",
  fund_endDate: "End Of Life",
  fund_ExpCarry: "Expected Carry",
  fund_numberOfInvestmentProjected: "Investments Projected",
  fund_capitalAvailable: "Capital Available",
  fund_recalledAmount: "Recalled Amount",
  AUC_IRR: "AUC IRR",
  fund_ficialSummary: "ficial Summary",
  fund_capitalReserved: "Capital Reserved",
  fund_valueDistributed: "Value Distributed",
  fund_numberOfInvestmentToDate: "Number of Investments to Date",
  fund_PartnerExp_toDate: "Partner Expenses to Date",
  fund_OrgFees_toDate: "Organizational Fees to Date",
  fund_MgmtFees_toDate: "Management Fees to Date",
  fund_treatmentOfCarriedInterestOnRemoval: "Carried Interest on Removal",
  Textboxes_Strategy: "Fund description",
  Textboxes_Outlook: "Fund Outlook",
  Textboxes_FundPipe: "Fund Investment Pipeline",
  "Textboxes_Co-InvestPipe": "Fund Co-Investment Pipeline",
  Textboxes_Exits: "Fund Exits",
  fund_netAssetValueGPCarried: "Net Asset Value GP Carried",
  fund_uncalledCapital: "Uncalled Capital",
  Textboxes_Overview: "Portfolio Qtrly Update",
  Main_Company_Name: "Clean Company Name",
  portfolio_proceeds: "Proceeds",
  portfolio_fmv: "FMV",
  portfolio_arr: "ARR",
  portfolio_ebit: "EBIT",
  portfolio_pbt: "PBT",
  portfolio_GP_Lead: "GP Lead",
  portfolio_yearOfInvestment: "Year of Investment",
  fund_commitmentPeriodEndDate: "Commitment Period End Date",
  PublicMkt_Status: "Public Market Status",
  portfolio_companyName: "Company Name",
  PublicMkt_Exchange: "Public Market Exchange",
  PublicMkt_Ticker: "Public Market Ticker",
  portfolio_companyState: "Company State",
  portfolio_StatusDate: "Status Date",
  portfolio_companyLogo: "Logo",
  portfolio_companyURL: "URL",
  portfolio_companyOverview: "Overview",
  portfolio_companyCountry: "Country",
  portfolio_firstInvestmentDate: "First Investment Date",
  portfolio_exitDate: "Exit Date",
  Previous_Name: "Previous Name",
  InvCostoDate_Dilutive: "Investment Cost to Date Dilutive",
  InvCostoDate_NonDilutive: "Investment Cost to Date Non Dilutive",
  Previous_Name_2: "Past other name",
  portfolio_realizedGainLoss: "Realized Investment Gain/Loss",
  portfolio_unrealizedCost: "Unrealized Investment Cost",
  portfolio_unrealizedNAV: "Unrealized Investment Net Asset Value",
  portfolio_currency: "Currency",
  portfolio_FiscalYearEnd: "Fiscal Year End",
  positive_impact: "Positive Impact",
  portfolio_realizedValue: "Realized Fund",
  portfolio_geoLocation: "Location coordinates",
  portfolio_updatedAt: "Updated At",
  portfolio_companyOverviews: "Company Description",
  portfolio_companyStates: "Company States",
  portfolio_sector: "Sector",
  Textboxes_QtrUpdate: "Qtrly Update",
  portfolio_revenue: "Revenue",
  portfolio_CapitalInvested_NonDil: "Capital Invested Non Dilutive",
  portfolio_reservedCapital: "Reserved Capital",
  portfolio_moicFinal: "MOIC Final",
  portfolio_unrealizedNAV_NonDil: "Unrealized NAV Non Dilutive",
  portfolio_initialInvt_NonDil: "Initial Investment Non Dilutive",
  portfolio_initialInvt_Dilutive: "Initial Investment Dilutive",
  portfolio_capitalInvested: "Capital Invested",
  portfolio_capitalReserved: "Capital Reserved",
  portfolio_ROI: "ROI",
  portfolio_unrealizedGainLoss: "Unrealized Gain/Loss",
  portfolio_totalGainLoss: "Total Gain/Loss",
  portfolio_moc: "MOC",
  portfolio_ebitda: "EBITDA",
  portfolio_initialInvt_FDO: "Fully Diluted Ownership",
  portfolio_latestNav: "Latest NAV",
  portfolio_tvpi: "Portfolio TVPI",
  portfolio_holdingPeriod: "Holding Period",
  portfolio_totalCumulativeValue: "Total Cumulative Value",
  portfolio_keyShareholders: "Key Shareholders",
  portfolio_grossMargin: "Gross Margin",
  PublicMkt_SharePrice: "Public Market Share Price",
  portfolio_realizedCost: "Realized Investment Cost",
};

export const restrictedKeys = [
    "pdf_link",
    "company_ID",
    "fund_ID",
    "portfolio_geoLocation",
    "fund_news",
    "fund_geoLocation",
    "AQ_Score ",
    "portfolio_news",
    "demoData",
];

export const fundStaticKeys: { [key: string]: number }[] = [
  { fund_logo: 1 },
  { Fund_Name: 1 },
  { fund_sectorFocus: 1 },
  { fund_region: 1 },
  { fund_strategy: 1 },
  { fund_stage: 1 },
  { fund_focusCountry: 1 },
  { fund_generalPartner: 1 },
  { fund_investmentAdvisor: 1 },
  { fund_investmentManager: 1 },
  { fund_teamBackground: 1 },
  { fund_commitment: 1 },
  { fund_vintage: 1 },
  { fund_endDate: 1 },
  { fund_capitalInvested: 1 },
  { fund_currency: 1 },
  { AQ_Score: 1 },
  { fund_active: 1 },
  { fund_FirstClosing: 1 },
  { fund_Fees: 1 },
  { fund_Basis: 1 },
  { fund_Recycling: 1 },
  { fund_Pref: 1 },
  { fund_Carry: 1 },
  { fund_fundSizeFinalClose: 1 },
  { fund_highlights: 2 },
  { fund_historicalTer: 2 },
  { fund_websiteURL: 2 },
  { fund_gpCommitment: 2 },
  { fund_hurdleRate: 2 },
  { fund_clawBack: 2 },
  { fund_removalWithCause: 2 },
  { fund_removalWithoutCause: 2 },
  { fund_keyPersonProvision: 2 },
  { fund_governingLawAndJurisdiction: 2 },
  { fund_legalStructure: 2 },
  { fund_partners: 2 },
  { fund_teamSize: 2 },
  { fund_botURL: 2 },
  { lp_investmentStake: 2 },
  { LP_name: 2 },
  { fund_ConcLim: 2 },
  { fund_PostInv: 2 },
  { fund_InvPer: 2 },
  { fund_overview: 2 },
  { fund_expCalledRecalled: 2 },
  { fund_percentageFundSizeLPA: 2 },
  { fund_percentageNICLPA: 2 },
  { fund_InvPer_ExpAct: 2 },
  { fund_Life_LPA: 2 },
  { fund_Life_Exp: 2 },
  { fund_treatmentOfCarriedInterestOnRemoval: 2 },
  { fund_ExpCarry: 2 },
];

export const fundQtrKeys: { [key: string]: number }[] = [
  { Reporting_Date: 1 },
  { fund_nav: 1 },
  { AUC_IRR: 1 },
  { Textboxes_Outlook: 1 },
  { Textboxes_QtrlyUpdate: 1 },
  { Textboxes_FundPipe: 1 },
  { "Textboxes_Co-InvestPipe": 1 },
  { fund_carriedInterest: 1 },
  { Textboxes_Exits: 1 },
  { Textboxes_ToBeRealized: 1 },
  { fund_dpi: 1 },
  { fund_tvpi: 1 },
  { fund_called: 1 },
  { fund_netAssetValueGPCarried: 1 },
  { fund_PartnerExp_toDate: 1 },
  { fund_OrgFees_toDate: 1 },
  { fund_MgmtFees_toDate: 1 },
  { fund_distributed: 1 },
  { fund_capitalCalled: 1 },
  { fund_capitalReserved: 1 },
  { fund_capitalAvailable: 1 },
  { fund_valueDistributed: 1 },
  { fund_recallableDistributions: 1 },
  { fund_gpCarriedDistribution: 1 },
  { fund_totalOtherAssetsLiabilities: 1 },
  { fund_financialSummary: 1 },
  { fund_keyDriversPreviousQTR: 1 },
  { fund_macroInductryDrivers: 1 },
  { fund_extraOrdinaryMovements: 1 },
  { fund_riskDisclosure: 1 },
  { fund_gpUpdates: 1 },
  { fund_corporateActions: 1 },
  { fund_Textboxes_QtrlyreportUpdate: 1 },
  { fund_valueNetAsset: 1 },
  { fund_ficialSummary: 1 },
  { Fund_Name: 2 },
  { AUC_GPDist: 2 },
  { AUC_GPNav: 2 },
  { AUC_Reserves: 2 },
  { Textboxes_Strategy: 2 },
  { fund_numberOfInvestmentToDate: 2 },
  { fund_numberOfInvestmentProjected: 2 },
  { fund_uncalledCapital: 2 },
  { fund_netIRR: 2 },
  { fund_rvpi: 2 },
  { fund_capitalInvested: 2 },
  { fund_IRR: 2 },
  { fund_recalledAmount: 2 },
];

export const portfolioQtrKeys: { [key: string]: number }[] = [
  { Reporting_Date: 1 },
  { Main_Company_Name: 1 },
  { Currency: 1 },
  { portfolio_firstInvestmentDate: 1 },
  { InvCostoDate_Dilutive: 1 },
  { PublicMkt_Status: 1 },
  { FDO: 1 },
  { portfolio_exitDate: 1 },
  { portfolio_proceeds: 1 },
  { portfolio_unrealizedNAV: 1 },
  { portfolio_unrealizedCost: 1 },
  { portfolio_fmv: 2 },
  { portfolio_revenue: 2 },
  { portfolio_ebitda: 2 },
  { LP_name: 2 },
  { portfolio_totalCumulativeValue: 2 },
  { portfolio_keyShareholders: 2 },
  { portfolio_arr: 2 },
  { portfolio_ebit: 2 },
  { portfolio_pbt: 2 },
  { portfolio_grossMargin: 2 },
  { portfolio_FiscalYearEnd: 2 },
  { PublicMkt_SharePrice: 2 },
  { portfolio_CapitalInvested_NonDil: 2 },
  { portfolio_reservedCapital: 2 },
  { portfolio_moicFinal: 2 },
  { portfolio_unrealizedNAV_NonDil: 2 },
  { portfolio_initialInvt_NonDil: 2 },
  { portfolio_initialInvt_Dilutive: 2 },
  { portfolio_capitalInvested: 2 },
  { portfolio_capitalReserved: 2 },
  { portfolio_ROI: 2 },
  { portfolio_unrealizedGainLoss: 2 },
  { portfolio_totalGainLoss: 2 },
  { portfolio_moc: 2 },
  { portfolio_realizedCost: 2 },
  { portfolio_realizedGainLoss: 2 },
  { portfolio_realizedValue: 2 },
  { PublicMkt_Exchange: 2 },
  { PublicMkt_Ticker: 2 },
  { Textboxes_QtrUpdate: 2 },
  { Fund_Name: 2 },
  { portfolio_companyName: 2 },
  { portfolio_initialInvt_FDO: 2 },
  { portfolio_GP_Lead: 2 },
];

export const portfolioStaticKeys: { [key: string]: number }[] = [
  { company_ID: 1 },
  { portfolio_companyName: 1 },
  { portfolio_updatedAt: 1 },
  { portfolio_companyLogo: 1 },
  { portfolio_companyURL: 1 },
  { portfolio_currency: 1 },
  { portfolio_yearOfInvestment: 1 },
  { portfolio_companyOverview: 1 },
  { fund_commitmentPeriodEndDate: 1 },
  { portfolio_sector: 1 },
  { portfolio_companyCountry: 1 },
  { portfolio_companyState: 1 },
  { positive_impact: 1 },
  { portfolio_StatusDate: 1 },
  { fund_ID: 1 },
  { portfolio_geoLocation: 1 },
  { Previous_Name: 1 },
  { Previous_Name_2: 1 },
  { portfolio_news: 1 },
  { ESG_Report: 1 },
  { demoData: 1 },
];
