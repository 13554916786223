import React, { CSSProperties, ChangeEvent, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import GridDeleteIcon from "@mui/icons-material/Delete";
import { ITeam } from "../../interfaces/fundRaising.interface";

interface TeamManagementDialogProps {
  open: boolean;
  onClose: () => void;
  team: ITeam[];
  onTeamChange: (index: number, event: ChangeEvent<HTMLInputElement>) => void;
  onAddTeamMember: () => void;
  onRemoveTeamMember: (index: number) => void;
  handleTeamProfileChange: (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => void;
}
const styles: { [key: string]: CSSProperties } = {
  label: {
    fontWeight: "bold",
    padding: "10px",
    width: "20px", // Align the text to the right within the label cell
  },
  dataCell: {
    verticalAlign: "middle", // Vertically center the content
    textAlign: "left", // Left align the content (for the inputs and icons)
  },
  label2: {
    fontWeight: "bold",
    padding: "5px 20px",
  },
  input: {
    marginTop: "3px",
    verticalAlign: "top",
    padding: "10px",
    border: "1px solid #ccc",
    width: "200px",
    borderRadius: "4px", // Make input take full width of its cell
  },
  buttonContainer: {
    textAlign: "center",
    marginTop: "20px", // Space above the button
  },
  button: {
    padding: "12px 24px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  button2: {
    marginLeft: "20px",
    padding: "12px 24px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

const TeamManagementDialog: React.FC<TeamManagementDialogProps> = ({
  open,
  onClose,
  team,
  onTeamChange,
  onAddTeamMember,
  onRemoveTeamMember,
  handleTeamProfileChange,
}) => {
  useEffect(() => {}, []);
  const [imageError, setImageError] = useState(false);
  const handleImageError = () => {
    setImageError(true);
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Manage Team Members</DialogTitle>
      <DialogContent>
        {/* Team Member Form */}
        {team.map((teamMember, index) => (
          <React.Fragment key={index}>
            <tr>
              <td style={styles.label}>Partner Name:</td>
              <td style={styles.dataCell}>
                <input
                  type="text"
                  name="partner_name"
                  value={teamMember.partner_name}
                  onChange={(e) => onTeamChange(index, e)}
                  style={styles.input}
                />
                <GridDeleteIcon
                  onClick={() => onRemoveTeamMember(index)}
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td style={styles.label}>Partner Background:</td>
              <td style={styles.dataCell}>
                <input
                  type="text"
                  name="partner_background"
                  value={teamMember.partner_background}
                  onChange={(e) => onTeamChange(index, e)}
                  style={styles.input}
                />
              </td>
            </tr>
            <tr>
              <td style={styles.label}>Partner URL:</td>
              <td style={styles.dataCell}>
                <input
                  type="text"
                  name="partner_url"
                  value={teamMember.partner_url}
                  onChange={(e) => onTeamChange(index, e)}
                  style={styles.input}
                />
              </td>
            </tr>
            <tr>
              <td style={styles.label}>Profile Image:</td>
              <td>
                <input
                  style={{
                    marginLeft: "10px",
                  }}
                  type="file"
                  id={`profile_image_${index}`}
                  name="profile_image"
                  onChange={(e) => handleTeamProfileChange(index, e)}
                  accept="image/jpeg, image/png"
                />
                {teamMember.profile_image && !imageError ? (
                  <img
                    src={
                      typeof teamMember.profile_image === "string"
                        ? teamMember.profile_image
                        : URL.createObjectURL(teamMember.profile_image)
                    }
                    width="75px"
                    height="75px"
                    onError={handleImageError}
                    alt="Dynamic Content"
                    onLoad={
                      typeof teamMember.profile_image === "object"
                        ? (e) => URL.revokeObjectURL(e.currentTarget.src)
                        : undefined
                    }
                  />
                ) : (
                  "No Image Found"
                )}

                {/* <input
                  type="text"
                  name="profile_image"
                  value={teamMember.profile_image}
                  onChange={(e) => onTeamChange(index, e)}
                  style={{
                    marginTop: "3px",
                    verticalAlign: "top",
                    padding: "10px",
                    width: "200px",
                  }}
                /> */}
              </td>
            </tr>
          </React.Fragment>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onAddTeamMember} color="primary">
          Add Team Member
        </Button>
        <Button onClick={onClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeamManagementDialog;
