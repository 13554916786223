import { CSSProperties, useState } from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import { FundStaticProps } from "../../interfaces/fundStatic.interface";

import {
  ESGfieldNames,
  ESGorderArray,
  IESGReport,
} from "../../interfaces/esg.interface";
import ESGForm from "../Esg_Update/ESGReportForm";

function ESGStatic({ id }: FundStaticProps) {
  const [fieldData, setFieldData] = useState({});
  const [message, setMessage] = useState("");
  const styles: { [key: string]: CSSProperties } = {
    label: {
      fontWeight: "bold",
      padding: "10px", // Add padding for aesthetic spacing
      textAlign: "right", // Align the text to the right within the label cell
    },
    input: {
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "100%", // Make input take full width of its cell
    },
    defaultValue: {
      padding: "10px", // Add padding for aesthetic spacing
      marginLeft: "20px", // Space from the input cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
      marginBottom: "20px",
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
  function createFieldData(responseData: IESGReport): {
    [key: string]: {
      type: string | Date;
      defaultValue: string | Boolean;
      name: string | undefined;
    };
  } {
    const excludedFields = [
      "_id",
      "ReportingDateKey",
      "ReportingDateString",
      "fund_nav",
      "fund_dpi",
      "fund_tvpi",
    ];

    const fieldData: {
      [key: string]: {
        type: string;
        defaultValue: string | Boolean;
        name: string | undefined;
      };
    } = {};

    ESGorderArray.forEach((key) => {
      if (excludedFields.includes(key) || !(key in responseData)) {
        return;
      }

      const value = responseData[key as keyof IESGReport];
      let type: string;

      if (key === "fr_logo") {
        type = "file";
      } else if (typeof value === "string") {
        type = "text";
      } else if (typeof value === "boolean") {
        type = "checkbox";
      } else if (typeof value === "number") {
        type = "number";
      } else {
        type = typeof value;
      }

      fieldData[key] = {
        type,
        defaultValue: value,
        name: ESGfieldNames?.[key as keyof IESGReport],
      };
    });
    return fieldData;
  }

  // Define an async function inside useEffect
  const fetchData = async () => {
    if (id == null || id === "") {
      setMessage("Please select a fund ID");
      return;
    } else {
      try {
        // Use params to send query parameters with a GET request
        const response = await axiosInstance.get("/esg_report/" + id);
        if (response.data === "error") {
          setMessage("Error while fetching Data.");
          // Handle error
        } else {
          if (response.data.data.length === 0) {
            setFieldData({});
            setMessage("There is no data with the given Date");
          } else {
            const responseData = response.data.data[0]; // Assuming this is your response data
            setFieldData(createFieldData(responseData));
            setMessage("");
          }
        }
      } catch (err) {
        setMessage("Error while fetching Data.");
        console.error(err);
      }
    }
  };
  const handleChangeData = async (formData: {
    [fieldName: string]: string | Boolean;
  }) => {
    try {
      const { company_ID: idToRemove, ...updatedFormData } = formData;
      const form = new FormData();
      form.append("company_ID", id);
      form.append("formData", JSON.stringify(updatedFormData));
      const response = await axiosInstance.post("/esgUpdate_report", form, {
        headers: {
          "Content-Type": "multipart/form-data", // or you can omit this line
        },
      });
      if (response.data === "error") {
        setMessage("Error while updating Data.");
      } else {
        setFieldData({});
        setMessage("Data updated successfully");
      }

      // Assuming you have the Base64 encoded ZIP string in a variable called `zipString`
    } catch (err) {
      setMessage("Error while updating Data.");
      console.error(err);
    }
  };
  return (
    <div>
      <h1>ESG Static</h1>
      <div style={styles.buttonContainer}>
        <button type="submit" style={styles.button} onClick={fetchData}>
          get previous data
        </button>
      </div>
      {Object.keys(fieldData).length !== 0 && (
        <>
          <ESGForm fieldData={fieldData} changedata={handleChangeData} />
        </>
      )}
      {message !== "" && <>{message}</>}
    </div>
  );
}
export default ESGStatic;
