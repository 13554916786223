import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { IFundStatic } from "../../interfaces/fundStatic.interface";
import { IPortfolioQtr } from "../../interfaces/portfolioQtr.interface";
import DynamicForm3 from "./workFlow_dataUpdateForm";
import {
  fieldNames,
  orderArray,
  PortfolioReviewReport,
} from "../../interfaces/portfoliReview";
import axiosInstance from "../../utils/axios/axios.instance";

function PortfolioReviewForm() {
  const [date, setDate] = useState<Date>(new Date());
  const [isSelected, setIsSelected] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [message, setMessage] = useState("");
  const [fieldData, setFieldData] = useState({});
  const [fieldData2, setFieldData2] = useState({});
  const fundID = useRef<HTMLSelectElement>(null);
  const portfolioID = useRef<HTMLSelectElement>(null);
  const [portfolioNames, setPortfolioNames] = useState<
    Array<{ [key: string]: string }>
  >([]);
  const [fundNames, setFundNames] = useState<Array<{ [key: string]: string }>>(
    []
  );
  const [selectedPorfolioID, setSelectedPortfolioID] = useState("");
  const [selectedFundID, setSelectedFundID] = useState("");
  function createFieldData(responseData: PortfolioReviewReport): {
    [key: string]: {
      type: string | Date;
      defaultValue: string | Number;
      name: string | undefined;
    };
  } {
    const excludedFields = [
      "_id",
      "fund_ID",
      "company_ID",
      "distribution_ID",
      "ReportingDateKey",
    ];
    const fieldData: {
      [key: string]: {
        type: string | Date;
        defaultValue: string | Number;
        name: string | undefined;
      };
    } = {};

    orderArray.forEach((key: any) => {
      if (excludedFields.includes(key) || !(key in responseData)) {
        return;
      }

      const value = responseData[key as keyof PortfolioReviewReport];
      let type: string;

      if (typeof value === "string") {
        type = "text";
      } else if (typeof value === "boolean") {
        type = "checkbox";
      } else if (typeof value === "number") {
        type = "number";
      } else if (Array.isArray(value)) {
        type = "array";
      } else {
        type = typeof value;
      }

      fieldData[key] = {
        type,
        defaultValue: value,
        name: fieldNames?.[key as keyof PortfolioReviewReport],
      };
    });
    return fieldData;
  }
  const fetchData = async (id: string, id2: string) => {
    try {
      setFieldData({});
      const response = await axiosInstance.get(
        `/getPortfolioReviewByID/${id2}/${id}/${selectedFundID}`
      );
      const response2 = await axiosInstance.get(
        `/getPortfolioReviewCurrentData/${id2}/${id}/${selectedFundID}`
      );
      if (response.data === "error") {
        setMessage("Error while fetching Data.");
        // Handle error
      } else {
        if (response.data.data.length === 0) {
          setMessage("There is no data with the given Date");
        } else {
          setMessage("");
          setIsSelected(true);
          const responseData = response.data.data[0];
          setFieldData(createFieldData(responseData));
          console.log(responseData);
          console.log(createFieldData(responseData));
          const response2Data = response2.data.data[0];
          if (response2Data !== undefined) {
            setIsUpdate(true);
            setFieldData2(createFieldData(response2Data));
          } else {
            setFieldData2({
              Reporting_Date: {
                type: "date",
                defaultValue: date
                  .toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                  .replace(/\//g, "-"),
                name: "Reporting Date",
              },
              fund_ID: {
                type: "text",
                defaultValue: selectedFundID,
                name: "Fund ID",
              },
              company_ID: {
                type: "text",
                defaultValue: selectedPorfolioID,
                name: "Company ID",
              },
              portfolio_companyName: {
                type: "text",
                defaultValue: responseData.portfolio_companyName,
                name: "Company Name",
              },
              Fund_Name: {
                type: "text",
                defaultValue: responseData.Fund_Name,
                name: "Fund Name",
              },
            });
            console.log({
              Reporting_Date: {
                type: "date",
                defaultValue: date
                  .toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                  .replace(/\//g, "-"),
                name: "Reporting Date",
              },
              fund_ID: {
                type: "text",
                defaultValue: selectedFundID,
                name: "Fund ID",
              },
              company_ID: {
                type: "text",
                defaultValue: selectedPorfolioID,
                name: "Company ID",
              },
              portfolio_companyName: {
                type: "text",
                defaultValue: responseData.portfolio_companyName,
                name: "Company Name",
              },
              Fund_Name: {
                type: "text",
                defaultValue: responseData.Fund_Name,
                name: "Fund Name",
              },
            });
          }
        }
      }
    } catch (err) {
      setMessage("Error while fetching Data.");
      console.error(err);
    }
  };

  const getPortfolioNames = async (fund_ID: string = "") => {
    try {
      if (fund_ID === "") {
        fund_ID = fundID.current?.value ?? "";
      }
      const response = await axiosInstance.get("/getCompanies/" + fund_ID);
      const responseData = response.data.data;
      responseData.sort(
        (
          a: { portfolio_companyName: string },
          b: { portfolio_companyName: string }
        ) => {
          return a.portfolio_companyName
            .toLowerCase()
            .localeCompare(b.portfolio_companyName.toLowerCase());
        }
      );

      const portfolioNames = responseData.map((portfolio: IPortfolioQtr) => {
        return {
          [portfolio.portfolio_companyName as string]: portfolio.company_ID,
        };
      });
      if (portfolioID.current) {
        portfolioID.current.value = Object.values(
          portfolioNames[0]
        )[0] as string;
        portfolioID.current.selectedIndex = 0;
      }
      setPortfolioNames(portfolioNames);
      setSelectedPortfolioID(Object.values(portfolioNames[0])[0] as string);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getFundNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangeData = async (formData: {
    [fieldName: string]: string | undefined | Date | File | Boolean;
  }) => {
    try {
      // const form = new FormData();
      // Object.keys(formData).forEach((key) => {
      //   form.append(key, formData[key] as string);
      // });
      const form = new FormData();
      form.append("formData", JSON.stringify(formData));
      form.append("fund_ID", selectedFundID);
      form.append("company_ID", selectedPorfolioID);
      form.append("Reporting_Date", formatDate(date));
      if (isUpdate) {
        const response = await axiosInstance.patch(
          "/updatePortfolioReviewByID",
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data", // or you can omit this line
            },
          }
        );
        if (response.data === "error") {
          setMessage("Error while updating Data.");
        } else {
          setFieldData({});
          setMessage("Data updated successfully");
        }
      } else {
        const response = await axiosInstance.post("/addPortfolioReview", form, {
          headers: {
            "Content-Type": "multipart/form-data", // or you can omit this line
          },
        });
        if (response.data === "error") {
          setMessage("Error while updating Data.");
        } else {
          setFieldData({});
          setMessage("Data updated successfully");
        }
      }

      // Assuming you have the Base64 encoded ZIP string in a variable called `zipString`
    } catch (err) {
      setMessage("Error while updating Data.");
      console.error(err);
    } finally {
      setTimeout(() => {
        fetchData(selectedPorfolioID, formatDate(date));
        setIsUpdate(false);
        setMessage("");
      }, 3000);
    }
  };

  const getFundNames = async () => {
    try {
      const response = await axiosInstance.get("/fund-names");
      const responseData = response.data.data;
      responseData.sort(
        (a: { Fund_Name: string }, b: { Fund_Name: string }) => {
          return a.Fund_Name.toLowerCase().localeCompare(
            b.Fund_Name.toLowerCase()
          );
        }
      );
      const fundNames = responseData.map((fund: IFundStatic) => {
        return { [fund.Fund_Name]: fund.fund_ID };
      });
      // setSelectedFundID(Object.values(fundNames[0])[0] as string);
      setFundNames(fundNames);
      if (fundID.current) {
        fundID.current.value = Object.values(fundNames[0])[0] as string;
      }
      setSelectedFundID(Object.values(fundNames[0])[0] as string);
      getPortfolioNames(Object.values(fundNames[0])[0] as string);
    } catch (error) {
      console.error(error);
    }
  };
  function formatDate(dateString: Date) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // +1 because getMonth() returns 0-11
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  return (
    <React.Fragment>
      <Paper
        style={{
          padding: "10px",
        }}
      >
        <h1>Portfolio Review</h1>
        <div>
          <div
            style={{
              display: "flex",
              paddingBottom: "50px",
              alignItems: "center",
              padding: "10px",
              boxSizing: "border-box", // Ensure padding is included in the width
              flexWrap: "wrap",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  minWidth: "150px",
                  textAlign: "center",
                  alignSelf: "center",
                }}
              >
                <label
                  style={{
                    marginTop: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Select a date :
                </label>
              </div>
              <div>
                <DatePicker
                  format="dd-MM-yyyy"
                  label="Reporting Date"
                  value={date}
                  onChange={(newValue) => {
                    if (newValue !== null) {
                      setDate(newValue);
                    }
                  }}
                />
              </div>
            </div>
            <div>
              <select
                ref={fundID}
                onChange={() => {
                  setSelectedFundID(fundID.current?.value ?? "");
                  getPortfolioNames();
                }}
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "#333",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  outline: "none",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                  width: "225px",
                  cursor: "pointer",
                  height: "50px",
                  marginLeft: "40px",
                }}
              >
                {fundNames.map((fund, index) => {
                  const fundName = Object.keys(fund)[0];
                  const fundID = fund[fundName];
                  return (
                    <option key={index} value={fundID}>
                      {fundName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <select
                ref={portfolioID}
                onChange={(e) => {
                  portfolioNames.forEach((portfolio) => {
                    if (Object.keys(portfolio)[0] === e.target.value) {
                      setSelectedPortfolioID(Object.values(portfolio)[0]);
                    }
                  });
                }}
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "#333",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  outline: "none",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                  width: "225px",
                  cursor: "pointer",
                  height: "50px",
                  marginLeft: "20px",
                }}
              >
                {portfolioNames.map((portfolio, index) => {
                  const portfolioName = Object.keys(portfolio)[0];
                  return (
                    <option key={index} value={portfolioName}>
                      {portfolioName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div style={{ alignSelf: "center" }}>
              <button
                onClick={() => {
                  fetchData(selectedPorfolioID, formatDate(date));
                }}
                type="submit"
                style={{
                  marginLeft: "40px",
                  padding: "12px 24px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Get data
              </button>
            </div>
          </div>

          {isSelected && (
            <DynamicForm3
              fieldData={fieldData}
              fieldData2={fieldData2}
              changedata={handleChangeData}
              staticData={false}
              date={formatDate(date)}
              isUpdate={isUpdate}
            />
          )}
          {message !== "" && <>{message}</>}
        </div>
      </Paper>
    </React.Fragment>
  );
}
export default PortfolioReviewForm;
