import React, { PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";

// Extend the interface from PropsWithChildren to include the children prop
interface ScrollableBoxProps extends PropsWithChildren<{}> {
  width?: string;
  height?: string;
}

const ScrollableBox = styled(Box)<ScrollableBoxProps>(
  ({ theme, width, height }) => ({
    width: width || "300px", // Default width if not provided
    height: height || "200px", // Default height if not provided
    overflow: "auto",
    // Custom scrollbar styles...
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "10px",
      "&:hover": {
        background: "#555",
      },
    },
    backgroundColor: "white",
    paddingLeft: "15px",
    scrollbarWidth: "thin",
    scrollbarColor: "#888 #f1f1f1",
    borderRadius: "10px",
    paddingTop: "1rem",
  })
);

// Component that uses ScrollableBox and passes all props along, including children
const CustomScrollComponent: React.FC<ScrollableBoxProps> = (props) => {
  return <ScrollableBox {...props}>{props.children}</ScrollableBox>;
};

export default CustomScrollComponent;
