import { useAuth0 } from "@auth0/auth0-react";
import {
  Paper,
  Typography,
  Divider as MuiDivider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import axiosInstance from "../../utils/axios/axios.instance";
const Divider = styled(MuiDivider)(spacing);

function InvestmentAnalysis() {
  const { user } = useAuth0();
  const [file, setFile] = useState<File | null>(null);
  const [userEmail, setUserEmail] = useState("");
  const [summary, setSummary] = useState("");
  const [fundID, setfundID] = useState("");
  const [fundIDs, setfundIDs] = useState([]);
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [years, setYears] = useState<number[]>([]);
  const [fund_name, setFund_name] = useState("");
  const [fundKeys, setFundKeys] = useState<{ [key: string]: any }>({});

  const quarters = [
    { name: "Q1", value: "31-03" },
    { name: "Q2", value: "30-06" },
    { name: "Q3", value: "30-09" },
    { name: "Q4", value: "31-12" },
  ];
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const resetData = () => {
    setFile(null);
    setSummary("");
  };
  const handleFundChange = (event: any) => {
    setFund_name(fundKeys[event.target.value]);
    setfundID(event.target.value);
    setSelectedQuarter("");
    setSelectedYear("");
  };

  const generateYears = (startYear: number): number[] => {
    const currentYear = new Date().getFullYear();
    const years: number[] = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years.reverse();
  };
  const handleQuarterChange = (event: any) => {
    const quarter = event.target.value;
    setSelectedQuarter(quarter);
  };

  const handleYearChange = (event: any) => {
    const year = event.target.value;
    setSelectedYear(year);
  };
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (
      event.dataTransfer.items &&
      event.dataTransfer.items[0].kind === "file"
    ) {
      const droppedFile = event.dataTransfer.items[0].getAsFile();
      setFile(droppedFile);
    }
  };
  const getFundNames = async () => {
    const response = await axiosInstance.get("/fund-names");
    const responseData = response.data.data;
    const sortedData = responseData.sort(
      (a: { Fund_Name: string }, b: { Fund_Name: string }) => {
        return a.Fund_Name.toLowerCase().localeCompare(
          b.Fund_Name.toLowerCase()
        );
      }
    );
    setfundIDs(sortedData);
    setFund_name(sortedData[0]["Fund_Name"]);
    setfundID(sortedData[0]["fund_ID"]);
    let keys: { [key: string]: any } = {};
    sortedData.map((fund: { [x: string]: any }) => {
      keys[fund["fund_ID"]] = fund["Fund_Name"];
    });
    setFundKeys(keys);
  };
  const setCurrentDate = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    let currentQuarter = "";
    let currentYear = currentDate.getFullYear();

    if (currentMonth >= 1 && currentMonth <= 3) {
      currentQuarter = "31-12";
      currentYear = currentYear - 1;
    } else if (currentMonth >= 4 && currentMonth <= 6) {
      currentQuarter = "31-03";
    } else if (currentMonth >= 7 && currentMonth <= 9) {
      currentQuarter = "30-06";
    } else {
      currentQuarter = "30-09";
    }
    setSelectedQuarter(currentQuarter);
    setSelectedYear(currentYear.toString());
  };
  useEffect(() => {
    if (user?.email) {
      setUserEmail(user.email);
    }
    const dynamicYears = generateYears(2000);
    setYears(dynamicYears);
    getFundNames();
    setCurrentDate();
  }, []);

  return (
    <Paper style={{ width: "100%", paddingTop: "40px" }}>
      <Helmet title="Quarterly Updates" />

      <Typography variant="h3">Investment Analysis</Typography>
      <Divider my={6} />
      {/* <Typography
        style={{
          textAlign: "justify",
          marginBottom: "20px",
          width: "80%",
        }}
      >
        This tool analyzes the provided pdfs and generates a summary of the
        content.
      </Typography> */}

      <div
        style={{
          width: "100%",
          display: "flex",
          alignContent: "center",
          alignSelf: "centers",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "50%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select fund name:
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Funds</InputLabel>
                <Select
                  value={fundID}
                  style={{ maxWidth: "300px" }}
                  onChange={handleFundChange}
                  label="Funds"
                >
                  {fundIDs.map((fund) => (
                    <MenuItem key={fund["Fund_Name"]} value={fund["fund_ID"]}>
                      {fund["Fund_Name"]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select reporting quarter and year:
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Quarter</InputLabel>
                    <Select
                      value={selectedQuarter}
                      onChange={handleQuarterChange}
                      label="Quarter"
                    >
                      {quarters.map((quarter) => (
                        <MenuItem key={quarter.name} value={quarter.value}>
                          {quarter.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Year Selector */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Year</InputLabel>
                    <Select
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300, // Adjust max height to your preference
                          },
                        },
                      }}
                      defaultValue={selectedYear}
                      value={selectedYear}
                      onChange={handleYearChange}
                      label="Year"
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div
            style={{
              marginTop: "50px",
              border: "2px dashed gray",
              padding: "20px",
              cursor: "pointer",
              textAlign: "center",
            }}
            onDragOver={(event) => event.preventDefault()}
            onDrop={onDrop}
            onClick={() => inputFileRef.current?.click()}
          >
            {file ? file.name : "Drag & Drop or Click to Select a File"}
            <input
              type="file"
              style={{ display: "none" }}
              onChange={onFileChange}
              onClick={(e: any) => (e.target.value = "")}
              ref={inputFileRef} // Attach the ref to the input
            />
          </div>
          <div
            style={{
              width: "100%",
              marginTop: "20px",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            <TextField
              fullWidth
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              type="email"
              label="Email Address"
              variant="outlined"
              placeholder="email@example.com"
            />
            <button
              //   onClick={runscript}
              style={{
                marginTop: "20px",
                backgroundColor: "#007BFF",
                color: "white",
                border: "none",
                padding: "12px 24px",
                borderRadius: "20px",
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s, box-shadow 0.2s",
                cursor: "pointer",
                outline: "none",
              }}
            >
              Process
            </button>
          </div>
        </div>
      </div>
      {summary && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <button
              onClick={resetData}
              style={{
                backgroundColor: "#D32F2F",
                color: "white",
                border: "none",
                padding: "12px 24px",
                borderRadius: "4px",
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s, box-shadow 0.2s",
                cursor: "pointer",
                outline: "none",
              }}
            >
              Reset
            </button>
          </div>
        </>
      )}
    </Paper>
  );
}

export default InvestmentAnalysis;
