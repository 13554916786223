import React from "react";
import styled from "@emotion/styled";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooter: React.FC = ({ ...rest }) => {
  return (
    <Footer style={{ height: "70px" }}>
      <div style={{ color: "white", marginLeft: "17%", marginTop: "5%" }}>
        Version 1.0.6 03/06/2024
      </div>
    </Footer>
  );
};

export default SidebarFooter;
