import { useState, useRef, useEffect } from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import DOMPurify from "dompurify";
import CircularWithValueLabel from "../../components/CricularLoader";
type Portfolio = {
  _id: string;
  company_ID: string;
  portfolio_companyName: string;
};
type KeyValuePair = [string, string];

function NewsPage() {
  const createMarkup = (htmlContent: any) => {
    return { __html: DOMPurify.sanitize(htmlContent) };
  };
  const [keyValuePair, setKeyValuePair] = useState<KeyValuePair[]>([]);
  useEffect(() => {
    getID();
  }, []);
  const getID = async () => {
    try {
      const response = await axiosInstance.get("/get_portfolio_names"); // Type the response with <Fund[]>
      if (response.data.data !== "error") {
        const key_value: KeyValuePair[] = response.data.data.map(
          (item: Portfolio) => [item.portfolio_companyName, item.company_ID]
        );
        const sortedKeyValue = key_value.sort(([keyA], [keyB]) =>
          keyA.localeCompare(keyB)
        );
        setKeyValuePair(sortedKeyValue);
      } else {
        // Handle the error case as appropriate
        console.error("Not enough data found");
      }
    } catch (error) {
      // Handle the error case as appropriate
      console.error("There was an error fetching the fund names", error);
    }
  };
  const [loader, setLoader] = useState(0);
  const [data, setData] = useState("");
  const [summary, setSummary] = useState("");
  const inputFileRef = useRef<HTMLSelectElement>(null);

  const handleSelectionChange = () => {
    // You can access the selected value through the ref
    if (inputFileRef.current) {
    }
  };
  const runscript = async () => {
    const value = 1;
    setSummary("");
    let time = new Date().toISOString();
    time = time.replace(/:/g, "-");
    setData("Getting output... ");
    setInterval(() => {
      if (value < 100) {
        setLoader(value + 1);
      }
    }, 10);
    try {
      const inputTextValue = inputFileRef.current
        ? inputFileRef.current.value
        : "";

      const formData = new FormData();
      formData.append("inputText", inputTextValue);
      const response = await axiosInstance.post("/runNewsSummary", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        params: { timestamp: time, data: inputTextValue },
      });
      if (response.data === "error") {
        setSummary("Not enough data found");
      } else {
        setSummary(response.data);
      }
      setData("");
      setLoader(100);
      // Assuming you have the Base64 encoded ZIP string in a variable called `zipString`
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div style={{ alignContent: "center", alignSelf: "center" }}>
      <h2>Portfolio Company Overview</h2>
      <div
        style={{
          marginLeft: "25%",
          width: "50%",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <select
          ref={inputFileRef}
          onChange={handleSelectionChange}
          style={{
            backgroundColor: "#f2f2f2", // Background color
            color: "#333", // Text color
            padding: "10px", // Padding
            border: "1px solid #ccc", // Border
            borderRadius: "5px", // Border radius
            outline: "none", // Remove outline when selected
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
            width: "200px", // Width
            cursor: "pointer", // Cursor style
          }}
        >
          {keyValuePair.map(([key, value]) => (
            <option
              key={value}
              value={value}
              style={{
                backgroundColor: "white",
                color: "#333",
              }}
            >
              {key}
            </option>
          ))}
        </select>

        <button
          onClick={runscript}
          style={{
            marginLeft: "40%",
            marginTop: "20px",
            backgroundColor: "#673AB7",
            color: "white",
            border: "none",
            padding: "12px 24px",
            borderRadius: "4px",
            fontSize: "16px",
            fontWeight: "500",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s, box-shadow 0.2s",
            cursor: "pointer",
            outline: "none",
          }}
        >
          Process
        </button>

        {data !== "" && (
          <>
            <div style={{ display: "flex" }}>
              <div style={{ marginTop: "3rem" }}> {data} </div>
              <div style={{ marginTop: "2.5rem", paddingLeft: "2rem" }}>
                {" "}
                {loader > 0 && loader <= 100 && (
                  <CircularWithValueLabel
                    initialProgress={loader}
                    comp="noLoop"
                  />
                )}{" "}
              </div>
            </div>
          </>
        )}
      </div>
      <div
        style={{
          marginTop: "1rem",
          marginBottom: "10%",
          display: "flex",
        }}
      >
        {summary !== "" && (
          <div
            style={{
              paddingTop: "10px",
              marginRight: "4%",
              backgroundColor: "white",
            }}
            dangerouslySetInnerHTML={createMarkup(summary)}
          />
        )}
      </div>
    </div>
  );
}

export default NewsPage;
