import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

interface EditableTableProps {
    data: Array<any>;
}

const EditableTable = ({ data }: EditableTableProps) => {
    const [tableData, setTableData] = useState<Array<any>>([]);

    useEffect(() => {
        if (data && Array.isArray(data)) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            columns = data.length > 0 ? Object.keys(data[0]) : [];
            setTableData(data);
        } else {
            console.error("Invalid data provided to EditableTable component.");
        }
    }, [data]);

    // const handleInputChange = (e: any, rowIndex: any, column: any) => {
    //     const { value } = e.target;
    //     setTableData(prevTableData => {
    //         const newTableData = [...prevTableData];
    //         newTableData[rowIndex] = { ...newTableData[rowIndex], [column]: value };
    //         return newTableData;
    //     });
    // };

    let columns = data.length > 0 ? Object.keys(data[0]) : [];

    return (
        <TableContainer component={Paper}>
            <div style={{ height: '400px', overflow: 'auto', width: '100%' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column}>{column}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {columns.map((column) => (
                                    <TableCell key={column}>
                                        {row[column]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </TableContainer>
    );
};

export default EditableTable;
