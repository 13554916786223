import React, { useState, ChangeEvent, CSSProperties, useEffect } from "react";
import "./../../Style/dynamicForm2.css";
import TextField from "@mui/material/TextField";
interface DynamicFormProps {
  fieldData: {
    [fieldName: string]: {
      type: string | Boolean;
      defaultValue: string;
      name: string;
    };
  };
  changedata: (formData: { [fieldName: string]: string | Boolean }) => void;
}
interface FormData {
  [fieldName: string]: string | Boolean;
}

const ESGForm: React.FC<DynamicFormProps> = ({ fieldData, changedata }) => {
  useEffect(() => {
    const updatedFormState: FormData = {};
    setImageError(false);
    Object.keys(fieldData).forEach((fieldName) => {
      updatedFormState[fieldName] = fieldData[fieldName].defaultValue;
    });
    setFormData(updatedFormState);
  }, [fieldData]);
  const initialFormState: FormData = {};
  Object.keys(fieldData).forEach((fieldName) => {
    initialFormState[fieldName] = fieldData[fieldName].defaultValue || "";
  });
  const handleFileChange = (event: any) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      setFormData({
        ...formData,
        [event.target.name]: file, // Store the File object
      });
      changedFields[event.target.name] = file;
    }
  };

  const [imageError, setImageError] = useState(false);

  // Function to handle image load error
  const handleImageError = () => {
    setImageError(true);
  };

  const [formData, setFormData] = useState<FormData>(initialFormState);
  const [changedFields, setChangedFields] = useState<FormData>({});
  const styles: { [key: string]: CSSProperties } = {
    label: {
      fontWeight: "bold",
      padding: "10px",
      width: "20px", // Align the text to the right within the label cell
      verticalAlign: "top",
    },
    label2: {
      fontWeight: "bold",
      padding: "5px 20px",
    },
    input: {
      verticalAlign: "text-top",
      padding: "10px",
      border: "1px solid #ccc",
      width: "200px",
      borderRadius: "4px", // Make input take full width of its cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    button2: {
      marginLeft: "20px",
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, type, checked, value } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
    setChangedFields({
      ...changedFields,
      [name]: newValue,
    });
  };
  const handleSubmitForm2 = () => {
    changedata(changedFields);
    setChangedFields({});
  };
  const form = React.createRef<HTMLFormElement>();

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div style={styles.buttonContainer}>
        <button
          type="button"
          style={styles.button2}
          onClick={handleSubmitForm2}
        >
          Update
        </button>
      </div>
      <form ref={form}>
        <table
          style={{
            width: "60%",
            borderCollapse: "collapse",
            borderSpacing: 0,
            marginTop: "20px",
          }}
        >
          <tbody>
            {Object.keys(fieldData).map((fieldName, index, array) => {
              const isGoalCheckbox = fieldName.includes("_checkbox");
              // Determine the name of the corresponding description field, if this is a checkbox
              const descriptionFieldName = isGoalCheckbox
                ? fieldName.replace("_checkbox", "_description")
                : null;
              const urlFieldName = isGoalCheckbox
                ? fieldName.replace("_checkbox", "_url")
                : "";
              const curlFieldName = isGoalCheckbox
                ? fieldName.replace("_checkbox", "_curl")
                : "";
              // Determine if the next field in the array is the corresponding description for this checkbox
              const isNextFieldDescription =
                array[index + 1] === descriptionFieldName;
              const isNextFieldUrl = array[index + 1] === urlFieldName;
              const isNextFieldCUrl = array[index + 1] === curlFieldName;
              const desc =
                fieldName.includes("_description") ||
                fieldName.includes("_url") ||
                fieldName.includes("_curl");

              return (
                <tr
                  key={fieldName}
                  style={{
                    paddingTop: "30px",
                    marginBottom: "20px",
                  }}
                >
                  {!desc && (
                    <td style={{ width: "30%", ...styles.label }}>
                      {fieldData[fieldName].name}
                    </td>
                  )}
                  {fieldData[fieldName].type === "checkbox" ? (
                    <>
                      <td
                        style={{
                          marginTop: "3px",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="checkbox"
                          id={fieldName}
                          name={fieldName}
                          checked={Boolean(formData[fieldName])}
                          onChange={handleChange}
                          style={{}}
                        />
                        {(isNextFieldDescription ||
                          isNextFieldUrl ||
                          isNextFieldCUrl) &&
                          !!formData[fieldName] && (
                            <TextField
                              multiline
                              rows={Math.min(
                                String(
                                  formData[
                                    isNextFieldDescription
                                      ? descriptionFieldName
                                      : isNextFieldUrl
                                      ? urlFieldName
                                      : curlFieldName
                                  ]
                                ).length /
                                  50 +
                                  1,
                                6
                              )}
                              label={fieldData[fieldName].name}
                              variant="outlined"
                              id={
                                isNextFieldDescription
                                  ? descriptionFieldName
                                  : isNextFieldUrl
                                  ? urlFieldName
                                  : curlFieldName
                              }
                              name={
                                isNextFieldDescription
                                  ? descriptionFieldName
                                  : isNextFieldUrl
                                  ? urlFieldName
                                  : curlFieldName
                              }
                              value={
                                String(
                                  formData[
                                    isNextFieldDescription
                                      ? descriptionFieldName
                                      : isNextFieldUrl
                                      ? urlFieldName
                                      : curlFieldName
                                  ]
                                ) || ""
                              }
                              onChange={handleChange}
                              style={{
                                marginLeft: "10px",
                                width: "84%",
                                marginBottom: "10px",
                                background: "#fff",
                                borderColor: "#007bff",
                                borderWidth: "1px",
                                borderRadius: "4px", // Rounded corners
                                padding: "5px", // Internal padding
                              }}
                            />
                          )}
                      </td>
                    </>
                  ) : (
                    !desc && ( // Only render non-checkbox fields or descriptions without a preceding checkbox
                      <td style={{ width: "100%" }}>
                        <TextField
                          multiline
                          rows={Math.min(
                            Math.floor(
                              String(formData[fieldName]).length / 65 + 1
                            ),
                            6
                          )}
                          label={fieldData[fieldName].name}
                          variant="outlined"
                          style={{
                            textAlign: "justify",
                            width: "90%",
                            marginBottom: "10px",
                            background: "#fff", // Adds a white background
                            borderColor: "#007bff", // Blue border color
                            borderWidth: "2px", // Border width
                            borderRadius: "4px", // Rounded corners
                            padding: "5px", // Internal padding
                          }}
                          type={fieldData[fieldName].type as string}
                          id={index.toString()}
                          name={fieldName}
                          value={String(formData[fieldName]) || ""}
                          onChange={handleChange}
                          disabled={fieldName === "LP_name"}
                        />
                      </td>
                    )
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default ESGForm;
