import React, { CSSProperties, ChangeEvent } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import GridDeleteIcon from "@mui/icons-material/Delete"; // Adjust the import path as necessary
import { ITrackRecord } from "../../interfaces/fundRaising.interface";

interface TrackRecordDialogProps {
  open: boolean;
  onClose: () => void;
  trackRecords: ITrackRecord[];
  onTrackRecordChange: (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => void;
  onAddTrackRecord: () => void;
  onRemoveTrackRecord: (index: number) => void;
}

const TrackRecordDialog: React.FC<TrackRecordDialogProps> = ({
  open,
  onClose,
  trackRecords,
  onTrackRecordChange,
  onAddTrackRecord,
  onRemoveTrackRecord,
}) => {
  const styles: { [key: string]: CSSProperties } = {
    label: {
      fontWeight: "bold",
      padding: "10px",
      width: "20px", // Align the text to the right within the label cell
    },
    label2: {
      fontWeight: "bold",
      padding: "5px 20px",
    },
    input: {
      marginTop: "3px",
      verticalAlign: "top",
      padding: "10px",
      border: "1px solid #ccc",
      width: "200px",
      borderRadius: "4px", // Make input take full width of its cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    button2: {
      marginLeft: "20px",
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Manage Track Records</DialogTitle>
      <DialogContent>
        <table>
          <tbody>
            {trackRecords.map((record, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td style={styles.label}>Date:</td>
                  <td>
                    <input
                      type="date"
                      name="tr_date"
                      value={record.tr_date}
                      onChange={(e) => onTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                    <GridDeleteIcon
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                      onClick={() => onRemoveTrackRecord(index)}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.label}>Fund:</td>
                  <td>
                    <input
                      type="text"
                      name="tr_fund"
                      value={record.tr_fund}
                      onChange={(e) => onTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.label}>Vintage:</td>
                  <td>
                    <input
                      type="text"
                      name="tr_vintage"
                      value={record.tr_vintage}
                      onChange={(e) => onTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.label}>Size:</td>
                  <td>
                    <input
                      type="number"
                      name="tr_size"
                      value={record.tr_size}
                      onChange={(e) => onTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.label}>Invested:</td>
                  <td>
                    <input
                      type="number"
                      name="tr_invested"
                      value={record.tr_invested}
                      onChange={(e) => onTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.label}>Realized:</td>
                  <td>
                    <input
                      type="number"
                      name="tr_realized"
                      value={record.tr_realized}
                      onChange={(e) => onTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.label}>Unrealized:</td>
                  <td>
                    <input
                      type="number"
                      name="tr_unrealized"
                      value={record.tr_unrealized}
                      onChange={(e) => onTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.label}>Total Value:</td>
                  <td>
                    <input
                      type="text"
                      name="tr_totalValue"
                      value={
                        (!isNaN(parseInt(record.tr_unrealized.toString()))
                          ? parseInt(record.tr_unrealized.toString())
                          : 0) +
                        (!isNaN(parseInt(record.tr_realized.toString()))
                          ? parseInt(record.tr_realized.toString())
                          : 0)
                      }
                      onChange={(e) => onTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.label}>Gross IRR:</td>
                  <td>
                    <input
                      type="text"
                      name="tr_grossIRR"
                      value={record.tr_grossIRR}
                      onChange={(e) => onTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.label}>Net IRR:</td>
                  <td>
                    <input
                      type="text"
                      name="tr_netIRR"
                      value={record.tr_netIRR}
                      onChange={(e) => onTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.label}>Net TVPI:</td>
                  <td>
                    <input
                      type="text"
                      name="tr_netTVPI"
                      value={record.tr_netTVPI}
                      onChange={(e) => onTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.label}>Loss Ratio:</td>
                  <td>
                    <input
                      type="text"
                      name="tr_lossRatio"
                      value={record.tr_lossRatio}
                      onChange={(e) => onTrackRecordChange(index, e)}
                      style={styles.input}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAddTrackRecord} color="primary">
          Add Track Record
        </Button>
        <Button onClick={onClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrackRecordDialog;
