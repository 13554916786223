import React, { useState } from "react";

const ImagePopup = ({ src, alt }: { src: string; alt: string }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);

  return (
    <>
      <img
        src={src}
        alt={alt}
        style={{
          cursor: "pointer",
          flex: 1,
          objectFit: "contain",
          width: "100%",
          height: "100%",
        }}
        onClick={toggleModal}
      />
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000, // Ensure it's on top of other content
          }}
        >
          <div
            style={{
              position: "relative",
              padding: 20,
              marginTop: 50,
              marginLeft: 245,
              backgroundColor: "#fff",
              borderRadius: 5,
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <img
              src={src}
              alt={alt}
              style={{
                height: "80vh",
                width: "auto",
                maxWidth: "100%",
                objectFit: "contain",
              }}
            />
            <button
              onClick={toggleModal}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                background: "none",
                border: "none",
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ImagePopup;
