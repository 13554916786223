export interface marketWatchCA {
  _id:  number;
  Reporting_Year:  string;
  canprov_Ontario_No_of_Deals:  number;
  canprov_Ontario_No_of_Companies:  number;
  canprov_Ontario_No_of_Firms:  number;
  canprov_Quebec_No_of_Deals:  number;
  canprov_Quebec_No_of_Companies:  number;
  canprov_Quebec_No_of_Firms:  number;
  canprov_British_Columbia_No_of_Deals:  number;
  canprov_British_Columbia_No_of_Companies:  number;
  canprov_British_Columbia_No_of_Firms:  number;
  canprov_Alberta_No_of_Deals:  number;
  canprov_Alberta_No_of_Companies:  number;
  canprov_Alberta_No_of_Firms:  number;
  canprov_Nova_Scotia_No_of_Deals:  number;
  canprov_Nova_Scotia_No_of_Companies:  number;
  canprov_Nova_Scotia_No_of_Firms:  number;
  canprov_Newfoundland_No_of_Deals:  number;
  canprov_Newfoundland_No_of_Companies:  number;
  canprov_Newfoundland_No_of_Firms:  number;
  canprov_Manitoba_No_of_Deals:  number;
  canprov_Manitoba_No_of_Companies:  number;
  canprov_Manitoba_No_of_Firms:  number;
  canprov_Saskatchewan_No_of_Deals:  number;
  canprov_Saskatchewan_No_of_Companies:  number;
  canprov_Saskatchewan_No_of_Firms:  number;
  canprov_New_Brunswick_No_of_Deals:  number;
  canprov_New_Brunswick_No_of_Companies:  number;
  canprov_New_Brunswick_No_of_Firms:  number;
  canprov_Prince_Edward_Island_No_of_Deals:  number;
  canprov_Prince_Edward_Island_No_of_Companies:  number;
  canprov_Prince_Edward_Island_No_of_Firms:  number;
  canprov_Total_No_of_Deals:  number;
  canprov_Total_No_of_Companies:  number;
  canprov_Total_No_of_Firms:  number;
  canind_Medical_Health_Life_Science_No_of_Deals:  number;
  canind_Medical_Health_Life_Science_No_of_Companies:  number;
  canind_Medical_Health_Life_Science_No_of_Firms:  number;
  canind_Information_Technology_No_of_Deals:  number;
  canind_Information_Technology_No_of_Companies:  number;
  canind_Information_Technology_No_of_Firms:  number;
  canind_Cleantech_No_of_Deals:  number;
  canind_Cleantech_No_of_Companies:  number;
  canind_Cleantech_No_of_Firms:  number;
  canind_Traditional_No_of_Deals:  number;
  canind_Traditional_No_of_Companies:  number;
  canind_Traditional_No_of_Firms:  number;
  canind_Total_No_of_Deals:  number;
  canind_Total_No_of_Companies:  number;
  canind_Total_No_of_Firms:  number;
  canfr_Amount_Raised_in_Range_CAD_Mil:  number;
  canfr_AB:  number;
  canfr_BC:  number;
  canfr_MB:  number;
  canfr_SK:  number;
  canfr_NF:  number;
  canfr_PEI:  number;
  canfr_NB:  number;
  canfr_NS:  number;
  canfr_ON:  number;
  canfr_QC:  number;
  canexits_IPO_Number_of_Deals:  number;
  canexits_IPO_No_Of_IPO_Exits:  number;
  canexits_IPO_No_of_Deals_w_MA_Deal_Value:  number;
  canexits_Trade_Sale_MA_Number_of_Deals:  number;
  canexits_Trade_Sale_MA_No_Of_IPO_Exits:  number;
  canexits_Trade_Sale_MA_No_of_Deals_w_MA_Deal_Value:  number;
  canexits_Secondary_Sale_Number_of_Deals:  number;
  canexits_Secondary_Sale_No_Of_IPO_Exits:  number;
  canexits_Secondary_Sale_No_of_Deals_w_MA_Deal_Value:  number;
  canexits_Total_Number_of_Deals:  number;
  canexits_Total_No_Of_IPO_Exits:  number;
  canexits_Total_No_of_Deals_w_MA_Deal_Value:  number;
  canque_Information_Technology_No_of_Deals:  number;
  canque_Information_Technology_No_of_Companies:  number;
  canque_Information_Technology_No_of_Firms:  number;
  canque_Medical_Health_Life_Science_No_of_Deals:  number;
  canque_Medical_Health_Life_Science_No_of_Companies:  number;
  canque_Medical_Health_Life_Science_No_of_Firms:  number;
  canque_Cleantech_No_of_Deals:  number;
  canque_Cleantech_No_of_Companies:  number;
  canque_Cleantech_No_of_Firms:  number;
  canque_Traditional_No_of_Deals:  number;
  canque_Traditional_No_of_Companies:  number;
  canque_Traditional_No_of_Firms:  number;
  canque_Total_No_of_Deals:  number;
  canque_Total_No_of_Companies:  number;
  canque_Total_No_of_Firms:  number;
}
export const fieldNamesCA: { [key in keyof marketWatchCA]: string } = {
  _id: "ID",
  Reporting_Year: "Reporting Year",
  canprov_Ontario_No_of_Deals: "Canprov Ontario No of Deals",
  canprov_Ontario_No_of_Companies: "Canprov Ontario No of Companies",
  canprov_Ontario_No_of_Firms: "Canprov Ontario No of Firms",
  canprov_Quebec_No_of_Deals: "Canprov Quebec No of Deals",
  canprov_Quebec_No_of_Companies: "Canprov Quebec No of Companies",
  canprov_Quebec_No_of_Firms: "Canprov Quebec No of Firms",
  canprov_British_Columbia_No_of_Deals: "Canprov British Columbia No of Deals",
  canprov_British_Columbia_No_of_Companies:
    "Canprov British Columbia No of Companies",
  canprov_British_Columbia_No_of_Firms: "Canprov British Columbia No of Firms",
  canprov_Alberta_No_of_Deals: "Canprov Alberta No of Deals",
  canprov_Alberta_No_of_Companies: "Canprov Alberta No of Companies",
  canprov_Alberta_No_of_Firms: "Canprov Alberta No of Firms",
  canprov_Nova_Scotia_No_of_Deals: "Canprov Nova Scotia No of Deals",
  canprov_Nova_Scotia_No_of_Companies: "Canprov Nova Scotia No of Companies",
  canprov_Nova_Scotia_No_of_Firms: "Canprov Nova Scotia No of Firms",
  canprov_Newfoundland_No_of_Deals: "Canprov Newfoundland No of Deals",
  canprov_Newfoundland_No_of_Companies: "Canprov Newfoundland No of Companies",
  canprov_Newfoundland_No_of_Firms: "Canprov Newfoundland No of Firms",
  canprov_Manitoba_No_of_Deals: "Canprov Manitoba No of Deals",
  canprov_Manitoba_No_of_Companies: "Canprov Manitoba No of Companies",
  canprov_Manitoba_No_of_Firms: "Canprov Manitoba No of Firms",
  canprov_Saskatchewan_No_of_Deals: "Canprov Saskatchewan No of Deals",
  canprov_Saskatchewan_No_of_Companies: "Canprov Saskatchewan No of Companies",
  canprov_Saskatchewan_No_of_Firms: "Canprov Saskatchewan No of Firms",
  canprov_New_Brunswick_No_of_Deals: "Canprov New Brunswick No of Deals",
  canprov_New_Brunswick_No_of_Companies:
    "Canprov New Brunswick No of Companies",
  canprov_New_Brunswick_No_of_Firms: "Canprov New Brunswick No of Firms",
  canprov_Prince_Edward_Island_No_of_Deals:
    "Canprov Prince Edward Island No of Deals",
  canprov_Prince_Edward_Island_No_of_Companies:
    "Canprov Prince Edward Island No of Companies",
  canprov_Prince_Edward_Island_No_of_Firms:
    "Canprov Prince Edward Island No of Firms",
  canprov_Total_No_of_Deals: "Canprov Total No of Deals",
  canprov_Total_No_of_Companies: "Canprov Total No of Companies",
  canprov_Total_No_of_Firms: "Canprov Total No of Firms",
  canind_Medical_Health_Life_Science_No_of_Deals:
    "Canind Medical Health Life Science No of Deals",
  canind_Medical_Health_Life_Science_No_of_Companies:
    "Canind Medical Health Life Science No of Companies",
  canind_Medical_Health_Life_Science_No_of_Firms:
    "Canind Medical Health Life Science No of Firms",
  canind_Information_Technology_No_of_Deals:
    "Canind Information Technology No of Deals",
  canind_Information_Technology_No_of_Companies:
    "Canind Information Technology No of Companies",
  canind_Information_Technology_No_of_Firms:
    "Canind Information Technology No of Firms",
  canind_Cleantech_No_of_Deals: "Canind Cleantech No of Deals",
  canind_Cleantech_No_of_Companies: "Canind Cleantech No of Companies",
  canind_Cleantech_No_of_Firms: "Canind Cleantech No of Firms",
  canind_Traditional_No_of_Deals: "Canind Traditional No of Deals",
  canind_Traditional_No_of_Companies: "Canind Traditional No of Companies",
  canind_Traditional_No_of_Firms: "Canind Traditional No of Firms",
  canind_Total_No_of_Deals: "Canind Total No of Deals",
  canind_Total_No_of_Companies: "Canind Total No of Companies",
  canind_Total_No_of_Firms: "Canind Total No of Firms",
  canfr_Amount_Raised_in_Range_CAD_Mil: "Canfr Amount Raised in Range CAD Mil",
  canfr_AB: "Canfr AB",
  canfr_BC: "Canfr BC",
  canfr_MB: "Canfr MB",
  canfr_SK: "Canfr SK",
  canfr_NF: "Canfr NF",
  canfr_PEI: "Canfr PEI",
  canfr_NB: "Canfr NB",
  canfr_NS: "Canfr NS",
  canfr_ON: "Canfr ON",
  canfr_QC: "Canfr QC",
  canexits_IPO_Number_of_Deals: "Canexits IPO Number of Deals",
  canexits_IPO_No_Of_IPO_Exits: "Canexits IPO No Of IPO Exits",
  canexits_IPO_No_of_Deals_w_MA_Deal_Value:
    "Canexits IPO No of Deals w MA Deal Value",
  canexits_Trade_Sale_MA_Number_of_Deals:
    "Canexits Trade Sale MA Number of Deals",
  canexits_Trade_Sale_MA_No_Of_IPO_Exits:
    "Canexits Trade Sale MA No Of IPO Exits",
  canexits_Trade_Sale_MA_No_of_Deals_w_MA_Deal_Value:
    "Canexits Trade Sale MA No of Deals w MA Deal Value",
  canexits_Secondary_Sale_Number_of_Deals:
    "Canexits Secondary Sale Number of Deals",
  canexits_Secondary_Sale_No_Of_IPO_Exits:
    "Canexits Secondary Sale No Of IPO Exits",
  canexits_Secondary_Sale_No_of_Deals_w_MA_Deal_Value:
    "Canexits Secondary Sale No of Deals w MA Deal Value",
  canexits_Total_Number_of_Deals: "Canexits Total Number of Deals",
  canexits_Total_No_Of_IPO_Exits: "Canexits Total No Of IPO Exits",
  canexits_Total_No_of_Deals_w_MA_Deal_Value:
    "Canexits Total No of Deals w MA Deal Value",
  canque_Information_Technology_No_of_Deals:
    "Canque Information Technology No of Deals",
  canque_Information_Technology_No_of_Companies:
    "Canque Information Technology No of Companies",
  canque_Information_Technology_No_of_Firms:
    "Canque Information Technology No of Firms",
  canque_Medical_Health_Life_Science_No_of_Deals:
    "Canque Medical Health Life Science No of Deals",
  canque_Medical_Health_Life_Science_No_of_Companies:
    "Canque Medical Health Life Science No of Companies",
  canque_Medical_Health_Life_Science_No_of_Firms:
    "Canque Medical Health Life Science No of Firms",
  canque_Cleantech_No_of_Deals: "Canque Cleantech No of Deals",
  canque_Cleantech_No_of_Companies: "Canque Cleantech No of Companies",
  canque_Cleantech_No_of_Firms: "Canque Cleantech No of Firms",
  canque_Traditional_No_of_Deals: "Canque Traditional No of Deals",
  canque_Traditional_No_of_Companies: "Canque Traditional No of Companies",
  canque_Traditional_No_of_Firms: "Canque Traditional No of Firms",
  canque_Total_No_of_Deals: "Canque Total No of Deals",
  canque_Total_No_of_Companies: "Canque Total No of Companies",
  canque_Total_No_of_Firms: "Canque Total No of Firms",
};
export const orderArrayCA: (keyof marketWatchCA)[] = [
  "_id",
  "Reporting_Year",
  "canprov_Ontario_No_of_Deals",
  "canprov_Ontario_No_of_Companies",
  "canprov_Ontario_No_of_Firms",
  "canprov_Quebec_No_of_Deals",
  "canprov_Quebec_No_of_Companies",
  "canprov_Quebec_No_of_Firms",
  "canprov_British_Columbia_No_of_Deals",
  "canprov_British_Columbia_No_of_Companies",
  "canprov_British_Columbia_No_of_Firms",
  "canprov_Alberta_No_of_Deals",
  "canprov_Alberta_No_of_Companies",
  "canprov_Alberta_No_of_Firms",
  "canprov_Nova_Scotia_No_of_Deals",
  "canprov_Nova_Scotia_No_of_Companies",
  "canprov_Nova_Scotia_No_of_Firms",
  "canprov_Newfoundland_No_of_Deals",
  "canprov_Newfoundland_No_of_Companies",
  "canprov_Newfoundland_No_of_Firms",
  "canprov_Manitoba_No_of_Deals",
  "canprov_Manitoba_No_of_Companies",
  "canprov_Manitoba_No_of_Firms",
  "canprov_Saskatchewan_No_of_Deals",
  "canprov_Saskatchewan_No_of_Companies",
  "canprov_Saskatchewan_No_of_Firms",
  "canprov_New_Brunswick_No_of_Deals",
  "canprov_New_Brunswick_No_of_Companies",
  "canprov_New_Brunswick_No_of_Firms",
  "canprov_Prince_Edward_Island_No_of_Deals",
  "canprov_Prince_Edward_Island_No_of_Companies",
  "canprov_Prince_Edward_Island_No_of_Firms",
  "canprov_Total_No_of_Deals",
  "canprov_Total_No_of_Companies",
  "canprov_Total_No_of_Firms",
  "canind_Medical_Health_Life_Science_No_of_Deals",
  "canind_Medical_Health_Life_Science_No_of_Companies",
  "canind_Medical_Health_Life_Science_No_of_Firms",
  "canind_Information_Technology_No_of_Deals",
  "canind_Information_Technology_No_of_Companies",
  "canind_Information_Technology_No_of_Firms",
  "canind_Cleantech_No_of_Deals",
  "canind_Cleantech_No_of_Companies",
  "canind_Cleantech_No_of_Firms",
  "canind_Traditional_No_of_Deals",
  "canind_Traditional_No_of_Companies",
  "canind_Traditional_No_of_Firms",
  "canind_Total_No_of_Deals",
  "canind_Total_No_of_Companies",
  "canind_Total_No_of_Firms",
  "canfr_Amount_Raised_in_Range_CAD_Mil",
  "canfr_AB",
  "canfr_BC",
  "canfr_MB",
  "canfr_SK",
  "canfr_NF",
  "canfr_PEI",
  "canfr_NB",
  "canfr_NS",
  "canfr_ON",
  "canfr_QC",
  "canexits_IPO_Number_of_Deals",
  "canexits_IPO_No_Of_IPO_Exits",
  "canexits_IPO_No_of_Deals_w_MA_Deal_Value",
  "canexits_Trade_Sale_MA_Number_of_Deals",
  "canexits_Trade_Sale_MA_No_Of_IPO_Exits",
  "canexits_Trade_Sale_MA_No_of_Deals_w_MA_Deal_Value",
  "canexits_Secondary_Sale_Number_of_Deals",
  "canexits_Secondary_Sale_No_Of_IPO_Exits",
  "canexits_Secondary_Sale_No_of_Deals_w_MA_Deal_Value",
  "canexits_Total_Number_of_Deals",
  "canexits_Total_No_Of_IPO_Exits",
  "canexits_Total_No_of_Deals_w_MA_Deal_Value",
  "canque_Information_Technology_No_of_Deals",
  "canque_Information_Technology_No_of_Companies",
  "canque_Information_Technology_No_of_Firms",
  "canque_Medical_Health_Life_Science_No_of_Deals",
  "canque_Medical_Health_Life_Science_No_of_Companies",
  "canque_Medical_Health_Life_Science_No_of_Firms",
  "canque_Cleantech_No_of_Deals",
  "canque_Cleantech_No_of_Companies",
  "canque_Cleantech_No_of_Firms",
  "canque_Traditional_No_of_Deals",
  "canque_Traditional_No_of_Companies",
  "canque_Traditional_No_of_Firms",
  "canque_Total_No_of_Deals",
  "canque_Total_No_of_Companies",
  "canque_Total_No_of_Firms",
];
