import React from 'react';
import { FormTableRowProps } from '../../interfaces/addDataForm.interface';
import { TextField } from '@mui/material';
const FormTableRow: React.FC<FormTableRowProps> = ({
    fieldName,
    fieldData,
    formData,
    handleChange,
    handleFileChange,
    handleImageError,
    imageError,
    image,
    styles,
    KeyNames,
    index,
    array,
}) => {
    return fieldName === "fund_logo" || fieldName === "portfolio_companyLogo" ? (
        <tr
            key={fieldName}
            style={{
                paddingTop: "30px",
                borderBottom: index !== array.length - 1 ? "1px solid #cccccc" : "none",
            }}
        >
            <td style={styles.label || { verticalAlign: "top" }}>{fieldName}</td>
            <td>
                <input
                    key={fieldName}
                    type="file"
                    id={fieldName}
                    name={fieldName}
                    onChange={handleFileChange}
                />
            </td>
            <>
                {image === "" ? (
                    fieldData[fieldName].defaultValue && !imageError ? (
                        <img
                            src={fieldData[fieldName].defaultValue as string}
                            width="100px"
                            height="100px"
                            onError={handleImageError}
                            alt="Dynamic Content"
                        />
                    ) : (
                        <p>No image available</p>
                    )
                ) : (
                    <img
                        src={image as string}
                        width="100px"
                        height="100px"
                        onError={handleImageError}
                        alt="Dynamic Content"
                    />
                )}
            </>
        </tr>
    ) : (
        <tr
            key={fieldName}
            style={{
                paddingTop: "30px",
                borderBottom: index !== array.length - 1 ? "1px solid #cccccc" : "none",
            }}
        >
            <td
                style={{
                    fontWeight: "bold",
                    padding: "5px 20px",
                    width: "20%",
                }}
            >
                {KeyNames[fieldName] || fieldName}
            </td>
            <td style={{ verticalAlign: "top", width: "25%" }}>
                <TextField
                    multiline
                    style={{
                        width: "100%",
                        background: "#fff",
                        borderColor: "#007bff",
                        borderWidth: "1px",
                        borderRadius: "4px", // Rounded corners
                        padding: "5px", // Internal padding
                    }}
                    minRows={1}
                    maxRows={7}
                    type={fieldData[fieldName].type}
                    id={fieldName}
                    name={fieldName}
                    value={formData[fieldName] || ""}
                    onInput={handleChange}
                    onChange={handleChange}
                    disabled={fieldName === "Reporting_Date"}
                />
            </td>
            <td style={styles.label2} width={"100%"}>
                {fieldData[fieldName].defaultValue}
            </td>
        </tr>
    );
};

export default FormTableRow;