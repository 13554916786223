import React from "react";
import Modal from "react-modal";

interface PdfViewerModalProps {
  isOpen: boolean;
  onClose: () => void;
  pdfUrl: string;
  pageNumber: number; // Add pageNumber as a prop
}

const PdfViewerModal: React.FC<PdfViewerModalProps> = ({
  isOpen,
  onClose,
  pdfUrl,
  pageNumber,
}) => {
  // Append the page number to the PDF URL
  const pdfUrlWithPage = `${pdfUrl}#page=${pageNumber}&toolbar=0`;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="PDF Viewer"
      style={{
        content: {
          marginLeft: "20%",
          width: "70%",
          height: "80%",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <button
        onClick={onClose}
        style={{ alignSelf: "flex-end", marginBottom: "10px" }}
      >
        Close
      </button>
      <div style={{ flex: 1 }}>
        <iframe
          src={pdfUrlWithPage}
          title="PDF Viewer"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </Modal>
  );
};

export default PdfViewerModal;
